import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { CommonService } from "src/app/_services/common.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class IsNotAuthenticatedGuard implements CanActivate {
  constructor(
    private tokenStorage: TokenStorageService,
    private router: Router,
    public _commonService: CommonService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user = this.tokenStorage.getUser();
    if (user["code"] === 200 && user["success"]) {
      return true;
    } else {
      if(!this.router.url.includes('login'))
      this.router.navigateByUrl("/login");
      return false;
    }
  }
}
