import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenStorageService } from './token-storage.service';

// Only for access releated and logins
const AUTH_API = environment.URLS.OMS_Gateway+'api/auth/';                   

// Authentiction and authorization of user then redirect
const GATEWAY_API = environment.URLS.OMS_Gateway+'omsapi/';

// Direct call to service without authentication n authorization
const BASEURL_OMSAPI = environment.URLS.OMS_BASEURL;


// start damage url
const BASEURL_API = environment.URLS.OMS_FINANCE ;

const BASEURL_GWAPI = environment.URLS.OMS_Gateway+'omsfinance/' ;
// end damage url

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  httpOptions: { headers: HttpHeaders; };

  constructor(private http: HttpClient, private token: TokenStorageService) {
    this.setHeaders(this.token.getToken());
  }

  setHeaders(token) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'token '+ token
      })
    };
  }
  public getLoggedInOrNot = new Subject(); 
  public selectedCountry = new Subject();
  historyItemId = new BehaviorSubject("");
  orderItemHistoryId = new BehaviorSubject("");
  actionItem = new BehaviorSubject("");



  microsftLogin(data: object) {
    //  return this.http.post<any>(GATEWAY_API + '/api/auth/microsoft/login', data);
    // environment.URLS.OMS_Gateway
      return this.http.post<any>( AUTH_API + 'microsoft/login', data);
    }

    
  // #######Authentication && Authorization api start###############  
  login(email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'login', {
      email,
      password
    }, this.httpOptions);
  }

  signup(name: string, email: string, team: string, password: string): Observable<any> {
    
    return this.http.post(AUTH_API + 'signup', {
      name,
      email,
      team,
      password
    });
  }

  logout(email: string, token: string): Observable<any> {
    return this.http.post(AUTH_API + 'logout', {
      email,
      token
    }, this.httpOptions);
  }

  genrateDn(responseBody): Observable<any> {
    return this.http.post('https://omsapiqa.moglilabs.com/Support/generateSupplierDN',
      responseBody)
  }
   
  createFeature(featureParent:string, featureName: string, url:string): Observable<any>{
    return this.http.post(AUTH_API + 'feature/add', {
      featureName,
      url,
      featureParent
    }, this.httpOptions);
  }

  myUserList( team:string, role:string): Observable<any>{
    return this.http.post(AUTH_API + 'user/list', {
      team,
      role
    }, this.httpOptions);
  }


  listFeature(userId:any, team:string, role:string): Observable<any>{
    return this.http.post(AUTH_API + 'feature/list', {
      userId,
      team,
      role
    }, this.httpOptions);
  }
 

  get_all_featureslist(role: any){
    return this.http.post(AUTH_API + 'feature/list', {role}, this.httpOptions)
  }

  getActive_list(userId:any): Observable<any>{
    return this.http.post(AUTH_API + 'feature/list', {userId}, this.httpOptions)
  }

  updateAccess( userId:any,  role:string, status:any, featureId:any): Observable<any>{
    return this.http.post(AUTH_API + 'feature/editaccess', {
      userId,
      role,
      status,
      featureId
    }, this.httpOptions);
  }

  editFeature_List_Menu(requestBody) : Observable <any>{
    return this.http.post(AUTH_API+'feature/edit', requestBody, this.httpOptions);
  }

  // getProfileMenus(): Observable<any> {
  //   return this.http.get(AUTH_API + `myfeature/list`, this.httpOptions);
  // }


  // ##########Authentication && Authorization End ##################

  // ##########Common Api start #######################
  get_supplier_List() : Observable <any>{
    let countrycode = localStorage.getItem('CountryCode');
    if(countrycode == 'me'){
      countrycode = 'UAE'
    }else if(countrycode == 'in'){
      countrycode = 'IND'
    }
    // OMS_BASEURL+ "supplier/list?countryCode=${countrycode}";
   return this.http.get(BASEURL_OMSAPI+`supplier/list?countryCode=${countrycode}`);
   //return this.http.get('http://localhost:8080'+`supplier/list?countryCode=${countrycode}`);
  }

  getcustomerInfo(requestBody): Observable<any>{
    let countrycode = localStorage.getItem('CountryCode');
    if(countrycode == 'me'){
      countrycode = 'UAE'
    }else if(countrycode == 'in'){
      countrycode = 'IND'
    }
   // requestBody['countryCode'] = countrycode;
    return this.http.post(GATEWAY_API+'oms/order/orderidlist/shippingAndBilling/details', requestBody, this.httpOptions)
  }
  
  getSupplierInfo(req): Observable<any>{
    return this.http.post(GATEWAY_API+`procurement/order/supplier/detail/listItemId`, req, this.httpOptions )
  }


  // ##############Common Api end #####################

  // #################Customer order processing api start ###################

  getCustomerOrderHistory(Id): Observable<any>{
    return this.http.get(BASEURL_OMSAPI+`oms/order/item/history?itemID=${Id}`)
  }

  // customer order api
  customerOrderList(requestBody,token) : Observable <any>{
    this.setHeaders(token);
    return this.http.post(GATEWAY_API+'oms/order/listing', requestBody, this.httpOptions);
  }

  update_Customer_Order(requestBody): Observable <any>{
    return this.http.post(GATEWAY_API+'order/state/update', requestBody, this.httpOptions)
  }

  getSupplierListbyItem(requestBody): Observable <any>{
    return this.http.post(BASEURL_OMSAPI+'order/state/supplierforitem', requestBody);
  }
 
  fetchCustomerInvoicePdf(requestBody) : Observable <any>{
    return this.http.post(GATEWAY_API+'oms/order/fetchCustomerOrderDoc' , requestBody, this.httpOptions);
  }


  // ######### Customer order processing api end ###############
  

  // ############## Purchase order Processing api Start#################
  get_Purchase_Order_List(requestBody,token) : Observable <any>{
    this.setHeaders(token);
    return this.http.post(GATEWAY_API+'procurement/order/list', requestBody, this.httpOptions);
  }


  getOrdersHistory(id): Observable<any>{
    return this.http.get(BASEURL_OMSAPI+`procurement/order/history?poId=${id}`)
  }

  update_Purchase_Order(requestBody): Observable <any>{
    return this.http.post(GATEWAY_API+'procurement/order/update', requestBody, this.httpOptions);
  }

  downloadPurchaseOrderList(request): Observable<any>{
    let countrycode = localStorage.getItem('CountryCode');
    if(countrycode == 'me'){
      countrycode = 'UAE'
    }else if(countrycode == 'in'){
      countrycode = 'IND'
    }
    request['countryCode'] = countrycode;
   // return this.http.post('http://localhost:8080/oms/order/listing', requestBody);
    //return this.http.post(`http://localhost:8080/procurement/order/list/download`, request)
    return this.http.post(GATEWAY_API+`procurement/order/list/download`, request, this.httpOptions)
  }

  downloadFinanceReport(request): Observable<any>{
    return this.http.post(GATEWAY_API +`oms/finance/financeReportDownload`, request, this.httpOptions)
  }

  downloadMonthlyReport(request): Observable<any>{
    
    //return this.http.post('http://localhost:8080/oms/finance/monthlyReport`, request);
    // return this.http.post('http://localhost:8080/oms/finance/monthlyReport', request);
    return this.http.post(GATEWAY_API +`oms/finance/monthlyReport`, request, this.httpOptions)
  }

  validate_CreateInvoice(requestBody): Observable <any>{
    return this.http.post(GATEWAY_API+'procurement/order/supplierInvoiceMapping', requestBody, this.httpOptions)
  }

  get_eligible_for_invoice_mapping(requestBody) : Observable <any> {
    return this.http.post(GATEWAY_API +'procurement/order/eligibleForInvoiceMapping', requestBody, this.httpOptions);
  }

  fetchSupplierInvoicePdf(requestBody) : Observable <any>{
    return this.http.post(GATEWAY_API+'procurement/order/fetchSupplierDoc' , requestBody, this.httpOptions);
  }
  


  getPayoutReportDownload(requestBody) : Observable <any>{
    //return this.http.post('http://localhost:8080//payoutReport/download', requestBody);
     return this.http.post(GATEWAY_API+'payoutReport/download', requestBody, this.httpOptions);
   }
  // ##############Purchase order Processing End ######################



  // ######Supplier Payment api start ############
  get_supplier_Ledger_List_ById(requestBody) : Observable <any> {
    return this.http.post(GATEWAY_API+'supplier/payment/ledger', requestBody, this.httpOptions);
  }

  get_list_Neft_Payment(requestBody): Observable <any>{
    return this.http.post(GATEWAY_API+'supplier/payment/neft/po', requestBody, this.httpOptions);
  }

  get_list_Advance_Payment(requestBody): Observable <any>{
    return this.http.post(GATEWAY_API+'supplier/payment/advance/po', requestBody, this.httpOptions);
  }
  
  // advance payment intiating
  pay_Advance_payment(requestBody): Observable <any>{
    return this.http.post(GATEWAY_API+'supplier/payment/advance/initiate', requestBody, this.httpOptions)
  }


  get_list_Payment_Reference(requestBody): Observable <any>{
    return this.http.post(GATEWAY_API+'supplier/payment/initiated/list', requestBody, this.httpOptions)
  }

  //generate sheet 
  generate_sheet(requestBody): Observable <any>{
    return this.http.post(GATEWAY_API+'supplier/payment/sheet/generate', requestBody, this.httpOptions)
  }

  get_sheet_data(requestBody): Observable <any>{
    return this.http.post(GATEWAY_API+'supplier/payment/sheet/list', requestBody, this.httpOptions)
  }

  get_CSV_Data(sheetId): Observable<any>{
    return this.http.get(GATEWAY_API+`supplier/payment/download?sheetId=${sheetId}`, this.httpOptions)
  }

  pay_Neft_payment(requestBody): Observable <any>{
    return this.http.post(GATEWAY_API+'supplier/payment/neft/initiate', requestBody, this.httpOptions)
  }

  upload_payment_utr(requestBody): Observable <any>{
    return this.http.post(GATEWAY_API+'supplier/payment/uploadutr', requestBody, this.httpOptions)
  }

  bulkUpload(requestBody): Observable <any>{
    return this.http.post(GATEWAY_API+'meaApi/order/bulkUpload', requestBody, this.httpOptions)
  }

  
  // ################Supplier Payment api start #########################


  // ##########Finance Reporting API ###########

  getFinanceReports(requestBody: any ): Observable<any>{
    return this.http.post(GATEWAY_API+'oms/finance/financeReport', requestBody, this.httpOptions);
  }



// ########## damage start  ##########
getDamageBusinessList(requestBody) {
  console.log(requestBody);
  // throw new Error("Method not implemented.");
  //return this.http.post(FINANCE_API_GW + `damage/request/list`, requestBody, this.httpOptions)
  return this.http.post(BASEURL_GWAPI+'damage/request/list', requestBody, this.httpOptions)
}

createDamageList(requestBody) {
 console.log(requestBody);
//  const headers = new HttpHeaders({
//   'Content-Type': 'multipart/form-data'
// });
  // throw new Error("Method not implemented.");
  //http://oms-finance.moglilabs.com/damage/request/list/damage/request/list
  // let headers = new HttpHeaders();
  // headers = headers.append('Content-Type', 'multipart/form-data');
 // headers = headers.append('enctype', 'multipart/form-data');

//  const headers = new HttpHeaders();
//  headers.append('Content-Type', 'multipart/form-data');

  return this.http.post(BASEURL_GWAPI+'damage/request/create', requestBody,this.httpOptions)
}



updateStatus(requestBody) {
//  alert(requestBody['requestIdList']);
  // throw new Error("Method not implemented.");
  //http://oms-finance.moglilabs.com/damage/request/list/damage/request/list
  return this.http.post(BASEURL_GWAPI + `damage/request/update`, requestBody,this.httpOptions)
}
getDamageBusinessListDownload(requestBody) {
  console.log(requestBody);
  // throw new Error("Method not implemented.");
  //http://oms-finance.moglilabs.com/damage/request/list/damage/request/list
  return this.http.post(BASEURL_API + `damage/download/list`, requestBody,this.httpOptions)
}
getDamageBusinessReject(requestBody) {
  console.log(requestBody);

  return this.http.post(BASEURL_GWAPI + `damage/request/update`, requestBody,this.httpOptions)

}
getDamageHistory(requestId): Observable<any> {
  return this.http.get(BASEURL_API + `damage/request/history?requestId=${requestId['requestId']}&country=IN`,this.httpOptions)
}
getViewRequest(requestId): Observable<any> {
  console.log("======>"+requestId['requestId']);
  return this.http.get(BASEURL_API + `damage/item/details?requestId=${requestId['requestId']}&country=IN`,this.httpOptions)
}
uploadUtr(requestBody):Observable<any>{
  console.log(requestBody);

  return this.http.post(BASEURL_GWAPI + `damage/bulk/updatePayment`, requestBody,this.httpOptions)

}
indiasupplierList(): Observable<any> {

  const countrycode = 'IN'

  // OMS_BASEURL+ "supplier/list?countryCode=${countrycode}";
  return this.http.get(BASEURL_API + `damage/supplier/list?countryCode=${countrycode}`,this.httpOptions);
  //return this.http.get('http://localhost:8080'+`supplier/list?countryCode=${countrycode}`);

}

supplierList(): Observable<any> {

  const countrycode = 'IN'

  // OMS_BASEURL+ "supplier/list?countryCode=${countrycode}";
  return this.http.get(BASEURL_OMSAPI + `supplier/list?countryCode=${countrycode}`,this.httpOptions);
  //return this.http.get('http://localhost:8080'+`supplier/list?countryCode=${countrycode}`);
}

//damage end


  
}