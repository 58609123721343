<div class="oms-appwrapeer">
  <app-header></app-header> 
  <app-loader *ngIf="_commonService.loaderIsVisible"></app-loader>
  <div class="container-oms">
    <router-outlet></router-outlet>
  </div>
</div>


<!-- <div class="oms-appwrapeer">
    <app-header></app-header> 
    <div class="container-oms">
      <mat-sidenav-container class="example-container" *ngIf='isLoggedIn'>
        <mat-sidenav #sidenav mode="side" [(opened)]="opened" (opened)="open()" (closed)="close()" style="margin-top: 5.1%;">
          <mat-nav-list>
            <mat-list-item *ngFor="let item of menuList_data">
              <mat-icon mat-list-icon>person</mat-icon>
              <h4 mat-line class="navigation-item-label" (click)="navigateTo(item)">{{item.featureName}}</h4>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon>person</mat-icon>
              <h4 mat-line class="navigation-item-label">Management A</h4>
            </mat-list-item>
          </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content [ngClass]="{'paddingLeft': (!opened) }">
          <p><mat-checkbox [(ngModel)]="opened"></mat-checkbox></p>
          <router-outlet></router-outlet>
        </mat-sidenav-content>
      </mat-sidenav-container> 
      <div *ngIf='!isLoggedIn'>
        <router-outlet></router-outlet>
      </div>
    </div>
</div> -->

<!-- <div id="app">
  <nav class="navbar navbar-expand-sm common-navigation">
  <div class="oms-wrapper">
    <div class="d-flex" style="float: left;">
      <a class="navbar-brand" href=""><img src="../assets/images/Component 4 – 1.svg" alt=""> </a>
    </div>
    <div class="collapse navbar-collapse" id="mynavbar" style="float: right;">

      <ul class="navbar-nav me-auto" *ngIf="!isLoggedIn">
        <li class="nav-item">
          <a href="/signup" class="nav-link" routerLink="signup">Sign Up</a>
        </li>
        <li class="nav-item">
          <a href="/login" class="nav-link" routerLink="login"><img width="23px" height="23px" src="../assets/images/profile-img.png" alt="">Login </a>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto" *ngIf="isLoggedIn">
        <li class="nav-item">
          <a href="/profile" class="nav-link" routerLink="profile">{{ username }}</a>
        </li>
        <li class="nav-item">
          <a href class="nav-link" (click)="logout()">LogOut</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div> -->

