<!-- <mat-toolbar color="primary">
  <mat-toolbar-row>
    <div>
      <img class="menu-icon" src="../assets/images/Component 4 – 1.svg" alt="">    
    </div>
      <span class="example-fill-remaining-space"></span>
      <span class="align-center"></span>
      <span class="example-spacer"></span>
      <button mat-button class="span_button" (click)="navigateTo('login')">Login</button>
      <button mat-button class="span_button" (click)="navigateTo('signup')">Signup</button>
      <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon> 
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <mat-icon>dialpad</mat-icon>
      <span>Redial</span>
    </button>
    <button mat-menu-item disabled>
      <mat-icon>voicemail</mat-icon>
      <span>Check voicemail</span>
    </button>
    <button mat-menu-item>
      <mat-icon>notifications_off</mat-icon>
      <span>Disable alerts</span>
    </button>
  </mat-menu>
   </mat-toolbar-row>
  </mat-toolbar> -->
  <mat-card>
    <mat-card-header>
      <mat-card-title>Signup in to OMS</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="!isSuccessful">
      <div class="col-md-12">
        <div class="card card-container">
          <img
            id="profile-img"
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            class="profile-img-card img"
          />
          <form
            *ngIf="!isSuccessful"
            name="form"
            (ngSubmit)="f.form.valid && onSubmit()"
            #f="ngForm"
            novalidate
          >
          <div class="form-group-main">
            <!-- <div class="form-group">
              <label for="name">Username</label>
              <input
                type="text"
                class="form-control"
                name="name"
                [(ngModel)]="form.name"
                required
                minlength="3"
                maxlength="20"
                #name="ngModel"
              />
              <div class="alert-danger" *ngIf="name.errors && f.submitted">
                <div *ngIf="name.errors.required">Username is required</div>
                <div *ngIf="name.errors.minlength">
                  Username must be at least 3 characters
                </div>
                <div *ngIf="name.errors.maxlength">
                  Username must be at most 20 characters
                </div>
              </div>
            </div> -->
            <div class="form-group">
              <label for="email">Email<em>*</em></label>
              <input
              autocomplete="new-password"
                type="email"
                class="form-control"
                name="email"
                [(ngModel)]="form.email"
                required
                email
                #email="ngModel"
              />
              <div class="alert-danger" *ngIf="email.errors && f.submitted">
                <div *ngIf="email.errors.required">Email is required</div>
                <div *ngIf="email.errors.email">
                  Email must be a valid email address
                </div>
              </div>
            </div>
            
            <div class="form-group">
              <label for="team">Team<em>*</em></label>
              <select class="form-control" id="team" name="team" autocomplete="new-password" [(ngModel)]="form.team" team  #team="ngModel" placeholder="select team" required>
                <option disabled=true>Select Team</option>
                <option *ngFor="let pow of teamList" [value]="pow">{{pow}}</option>
              </select>
              <div class="alert-danger" *ngIf="team.errors && f.submitted">
                <div *ngIf="team.errors.required">Team is required</div>
                <div *ngIf="team.errors.team">
                  Team must be Select
                </div>
              </div>
            </div>
    
            <div class="form-group">
              <label for="password">Password<em>*</em></label>
              <input
              autocomplete="new-password"
                type="password"
                class="form-control"
                name="password"
                [(ngModel)]="form.password"
                required
                minlength="6"
                #password="ngModel"
              />
              <div class="alert-danger" *ngIf="password.errors && f.submitted">
                <div *ngIf="password.errors.required">Password is required</div>
                <div *ngIf="password.errors.minlength">
                  Password must be at least 6 characters
                </div>
              </div>
            </div>
      
            <div class="form-group">
              <button class="btn btn-primary btn-block">Sign Up</button>
            </div>
      
            <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
              Signup failed!<br />{{ errorMessage }}
            </div>
          </div>
        </form>
        </div>
      </div>
    </mat-card-content>
    <mat-card-content *ngIf="isSuccessful">
      <div>
        <p>Your registration is successful. Please connect with your manager for Access!</p>
        <p><a [routerLink]="[routerLinkVariable]">Clik here to login</a></p>
      </div>
    </mat-card-content>
  </mat-card>

