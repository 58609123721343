<!-- <header class="finance-header">
  <div class="left">
    <img class="menu-icon" src="./assets/images/menu-2-line.svg">    
  </div>
  <div>
    <img class="menu-icon" src="../assets/images/Component 4 – 1.svg" alt="">    
  </div>
  <div *ngIf="!isLoggedIn">
      <span class="nav-item">
        <a href="/signup" class="nav-link" routerLink="signup">Sign Up</a>
      </span>
  </div>
  <div *ngIf="!isLoggedIn">
    <span class="nav-item">
      <a href="/login" class="nav-link" routerLink="login">Login<img width="23px" height="23px" src="../assets/images/profile-img.png" alt=""></a>
    </span>
  </div>
  <div class="right" *ngIf="isLoggedIn">
    <span class="nav-item">
      <a href="/profile" class="nav-link" routerLink="profile">{{ username }}</a>
    </span>
  </div>
  <div class="right" *ngIf="isLoggedIn">
    <div class="select_Country">
      <select class="form-control" id="country" name="country" [(ngModel)]="country" [ngModelOptions]="{standalone: true}"
        (change)="setCountry()" required>
        <option value="India">India</option>
        <option value="Middle East">Middle East</option>
      </select>
    </div>
    <div class="notification">
      <img class="p-catalog-profile" src="./assets/images/notification-3-line.svg">
      <span class="noti-badge"></span>
    </div>
    <div class="user" *ngIf="isLoggedIn">
      <img class="p-catalog-profile" src="./assets/images//businessman.svg">
    </div>
    <div>
      <span class="nav-item">
        <a href="" class="nav-link" (click)="logout()">LogOut</a>
      </span>
    </div>
  </div>
</header> -->


<nav class="navbar navbar-expand-sm common-navigation">
  <div class="oms-wrapper">
    <div class="d-flex">
      <a class="navbar-modal" *ngIf="isLoggedIn" (click)="headerMenu()"><img src="../assets/images/menu-2-line.svg" alt=""> </a>
      <a class="navbar-brand" href=""><img src="../assets/images/Component 4 – 1.svg" alt=""> </a>
    </div>
    <div class="collapse navbar-collapse" id="mynavbar">
      <ul class="navbar-nav me-auto">
        <!-- <li class="nav-item" *ngIf="isLoggedIn">
          <a class="nav-link" href="javascript:void(0)">
            <button class="dropdown-custom" mat-button [matMenuTriggerFor]="menu"><img src="../assets/images/werehouse.svg" alt="">Manesar <span class="material-icons"> arrow_drop_down  </span></button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item>Item 1</button>
              <button mat-menu-item>Item 2</button>
            </mat-menu>
          </a> -->


          <li class="nav-item" *ngIf="isLoggedIn">
            <!-- <select class="form-control" id="country" name="country" [(ngModel)]="country" [ngModelOptions]="{standalone: true}"
              (change)="setCountry()" required><span><img src="../assets/images/india-flag.svg" alt=""></span>
              <option value="India">India</option>
              <option value="Middle East">Middle East</option>
            </select> -->
            <ngx-flag-picker
            [selectedCountryCode]="selectedCountryCode"
            [countryCodes]="countryCodes"
            [customLabels]="customLabels"
            (changedCountryCode)="setCountry($event)">
          </ngx-flag-picker>
          </li>

        <!-- <li class="nav-item" *ngIf="isLoggedIn">
          <a class="nav-link" href="javascript:void(0)">
            <button class="dropdown-custom" mat-button [matMenuTriggerFor]="menu"><img src="../assets/images/india-flag.svg" alt="">India <span class="material-icons"> arrow_drop_down </span></button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item>Item 1</button>
              <button mat-menu-item>Item 2</button>
            </mat-menu>
          </a>
        </li> -->
        <li class="nav-item" *ngIf="isLoggedIn">
          <a class="nav-link" href="javascript:void(0)">
            <span class="material-icons">
              notifications
            </span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isLoggedIn">
          <a class="nav-link" href="javascript:void(0)">
            <img width="23px" height="23px" src="../assets/images/profile-img.png" routerLink="profile" alt="">
          </a>
        </li>
        <li *ngIf="!isLoggedIn && _router.url !== '/signup'">
          <a href="/signup" class="nav-link" routerLink="signup">Signup</a>
        </li>
        <li *ngIf="!isLoggedIn && _router.url !== '/login'">
          <a href="/login" class="nav-link" routerLink="login">Login</a>
        </li>
        <li *ngIf="isLoggedIn">
          <a href="" class="nav-link" (click)="logout()">Logout</a>
        </li>
      </ul>

    </div>
  </div>
</nav>
