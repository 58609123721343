import { Component, OnInit } from '@angular/core';

export interface PeriodicElement {
  orderId: string;
  itemId: string;
  productRef: string;
  status: string;
  createdAt : string;
  shippedAt : string;
  delivered : string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {orderId: '2907183', itemId: '922520', productRef: 'MSN2VL8OZSED9L', status: 'PAYMENT_PENDING', createdAt: 'PAYMENT_PENDING',shippedAt: 'Assignee',delivered : '2021-02-09 04:06:00'},
  {orderId: '2907183', itemId: '922520', productRef: 'MSN2VL8OZSED9L', status: 'PAYMENT_PENDING', createdAt: 'PAYMENT_PENDING',shippedAt: 'Assignee',delivered : '2021-02-09 04:06:00'},
  
];


const demo = {
  displayedColumns: ['orderId', 'itemId', 'productRef', 'status', 'createdAt','shippedAt','delivered'],
  dataSource: ELEMENT_DATA,
}


@Component({
  selector: 'app-customerinfo-dialog',
  templateUrl: './customerinfo-dialog.component.html',
  styleUrls: ['./customerinfo-dialog.component.scss']
})
export class CustomerinfoDialogComponent implements OnInit {

  dataSource = demo.dataSource;
  customerInfoDisplayedCloumn = demo.displayedColumns;
  selected = 'Delhi';
  country = 'India';
  checked = true;
  constructor() { }

  ngOnInit(): void {
  }

}
