<div class="oms-wrapper homeListingCards">
  <h1 class="main-heading">Hi {{ currentUser.name }},</h1>
  <h2 class="main-sub-heading" style="padding-left: unset;">We have few things for you to look at.</h2>
</div>
<div class="profile_Cards">
  <mat-card>
    <div style="display: flex;justify-content: space-between; padding-top: 10px; padding-bottom: 10px">
      <button mat-flat-button color="warn" style="background: #e0e4e7;color: black; height: fit-content;">10</button>
      <p style="font-weight: 600;font-size: 12px;">Orders Pending For Approval<span style="display: block; color: red;">2
          Pending morethen 24 hours</span></p>
      <button mat-flat-button color="warn" style="height: fit-content;">Take Action</button>
    </div>
    <mat-divider></mat-divider>
    <div style="display: flex;justify-content: space-between; padding-top: 10px; padding-bottom: 10px">
      <button mat-flat-button color="warn" style="background: #a140617d;color: black;">9</button>
      <p style="font-weight: 600;font-size: 12px;">Orders to be assigned</p>
      <button mat-flat-button color="warn">Take Action</button>
    </div>
    <mat-divider></mat-divider>
    <div style="display: flex;justify-content: space-between; padding-top: 10px; padding-bottom: 10px">
      <button mat-flat-button color="warn" style="background: #e0e4e7;color: black;">8</button>
      <p style="font-weight: 600;font-size: 12px;">Orders requres quick action</p>
      <button mat-flat-button color="warn">Take Action</button>
    </div>
    <mat-divider></mat-divider>
    <div style="display: flex;justify-content: space-between; padding-top: 10px; padding-bottom: 10px">
      <button mat-flat-button color="warn" style="background:#a140617d; color: black;">7</button>
      <p style="font-weight: 600;font-size: 12px;">Pending Packaging Requires</p>
      <button mat-flat-button color="warn">Take Action</button>
    </div>
    <mat-divider></mat-divider>
    <div style="display: flex;justify-content: space-between; padding-top: 10px; padding-bottom: 10px">
      <button mat-flat-button color="warn" style="background: #a140617d;color: black;">6</button>
      <p style="font-weight: 600;font-size: 12px;">Pending Mainfest</p>
      <button mat-flat-button color="warn">Take Action</button>
    </div>
  </mat-card>
</div>
<!-- <div class="main-sub-heading" id="mynavbar"> -->
<!---don't need for now-->
<!-- <ul class="navbar-nav me-auto" *ngIf="currentUser.role == 'Admin'">
        <li class="nav-item">
          <a  class="nav-link" routerLink="/addmenu">Add Menu</a>
        </li>
    </ul>
    <ul class="navbar-nav me-auto" *ngIf="currentUser.role == 'Admin' || currentUser.role == 'Manager' ">
        <li class="nav-item">
          <a  class="nav-link" routerLink="/editaccess">Edit Access</a>
        </li>
    </ul>
    <ul class="navbar-nav me-auto" *ngIf="currentUser.role == 'Admin'">
        <li class="nav-item">
          <a href="/addMenu" class="nav-link" routerLink="addmenu">Purchase Order</a>
        </li>
        <li class="nav-item">
          <a href="/editaccess" class="nav-link" routerLink="editaccess">Customer Order</a>
        </li>
    </ul> -->
<!-- <ul class="navbar-nav me-auto" *ngFor="let item of menuList_data">
      <li class="nav-item" *ngIf="(item.url == '/addmenu' && (currentUser.role == 'Admin')) || (item.url == '/editaccess' && (currentUser.role == 'Admin' || currentUser.role == 'Manager')) " >
        <a  class="nav-link" (click)=navigateTo(item)>{{item.featureName}}</a>
      </li>
      <li *ngIf="item.url != '/addmenu' && item.url != '/editaccess'">
        <a  class="nav-link" (click)=navigateTo(item)>{{item.featureName}}</a>
      </li>
  </ul> -->
<!-- </div> -->

<!-- <div class="container" *ngIf="currentUser; else loggedOut">
  <header class="jumbotron">
    <h3>
      <strong>{{ currentUser.username }}</strong> Profile
    </h3>
  </header>
  <p>
    <strong>Team:</strong>
    {{ currentUser.team }} 
  </p>
  <p>
    <strong>Email:</strong>
    {{ currentUser.email }}
  </p>
  <p>
  <strong>Roles:</strong>
      {{ currentUser.role }}
  </p>
</div> -->
<!-- 
<ng-template #loggedOut>
  Please login.
</ng-template> -->