import { Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MegaMenuComponent } from '../mega-menu/mega-menu.component';
import { TokenStorageService } from '../_services/token-storage.service';
import { AuthService } from '../_services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLoggedIn = false;
  currentUser: any;
  username?: string;
  logcheck:boolean = false;
  country :any;
  selectedCountryCode : string;
  countryCodes = ['me','in'];
  customLabels = {
    'me' : 'Middle East',
    'in': 'India'
  };

  constructor(public _router: Router, private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.currentUser = this.tokenStorage.getUser();
    this.authService.getLoggedInOrNot.subscribe((data) => {
      if(data && data['code'] == 200){
        this.isLoggedIn = true;
      }
    })
    if(!!this.tokenStorage.getToken()){
      this.isLoggedIn = true;
      console.log(this.isLoggedIn);
    }
    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.username;
    }
    this.country = localStorage.getItem('CountryCode');
    this.selectedCountryCode =  this.country;
    this.authService.selectedCountry.subscribe((data) => {
      console.log(data);
      if (data) {
        this.country = data;
        this.selectedCountryCode =  this.country;
      }
    })
  }
 
  logout() : void{
    console.log("inside logout");
    this.currentUser = window.localStorage.getItem('auth-user');
    window.localStorage.removeItem('auth-token');
    window.localStorage.removeItem('auth-user');
    let user  = JSON.parse(this.currentUser);
    this.authService.logout(user.email, user.accessToken).subscribe(
      (data) => {
        console.log("logout success"+data);
        if(data.success){
          this.isLoggedIn = false;
          this.tokenStorage.logoutUser();
        }
        else{
          console.log("test logout fail");
          this.isLoggedIn = false;
          this.tokenStorage.logoutUser();
          
          this.router.navigateByUrl('/');
        }       
        
      },(err) => {
        console.log("======= errrrrrr", err)
      }
    );
  }

  setCountry(value : string): void{
    this.selectedCountryCode = value;
    localStorage.setItem('CountryCode',  this.selectedCountryCode );
  }

  headerMenu() {
    const dialogRef = this.dialog.open(MegaMenuComponent,{
      width: '100%',
      backdropClass: 'megaMenuBackdropClass',
      panelClass: 'mega-menu'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }



  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
  }
}
