<div class="container">
	<br>
	<!--main functionalty-->
	<mat-card>
		<mat-card-header>
			<mat-card-title>Edit Profile for {{userData.name}}</mat-card-title>
			<!-- <h3>Edit Profile for {{userData.name}}</h3><br> -->
			<p><strong>Team Name: {{ userData.team }}</strong></p>
		</mat-card-header>
		<mat-card-content>
			<form *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
				<div class="form-group">
					<label for="role">Role<em>*</em></label>
					<select class="form-control" id="role" name="role" [(ngModel)]="form.role" required>
						<option>Select Role</option>
						<option *ngFor="let pow of roleList" ng-selected="userData.role == pow" [value]="pow">{{pow}}
						</option>
					</select>
				</div>

				<div class="form-group">
					<label for="status">Status<em>*</em></label>
					<select class="form-control" id="status" name="status" [(ngModel)]="form.status" required>
						<option value="1">Active</option>
						<option value="0">InActive</option>
					</select>
				</div>
				<h2 style="font-weight: 600; margin: unset;">Feature List</h2>
				<div class="form-group">
					<!-- <label for="featurelist"></label> -->
					<div class="form-control" *ngFor="let datarow of parent">
						<input type="checkbox" [(ngModel)]="datarow.isChecked" id="featurelist" name="featurelist"
							[value]="datarow.id" [ngModelOptions]="{standalone: true}"  (ngModelChange)="setChecks(datarow)"/>
							{{ datarow.featureName }}
							<div class="Child_Menu_flex">
								<div *ngFor="let childData of child" class="childMenu">
									<p *ngIf="childData.featureParent==datarow.featureName">
										<input   type="checkbox" [(ngModel)]="childData.isChecked" id="featurelist" name="featurelist"
										[value]="childData.id" [ngModelOptions]="{standalone: true}" />
										{{ childData.featureName }}
									</p>
								</div>
							</div>
					</div>
				</div>


				<!-- <div class="oms-wrapper homeListingCards">
	    <h2 class="main-sub-heading">Edit Profile for {{userData.name}}.</h2>
	    <mat-grid-list class="custom-grid-1" cols="2" rowHeight="2:1">
	        <mat-grid-tile>       
	            <mat-card class="card-style-1 mr-2p">
	                <mat-list>
	                    <mat-list-item>
	                        <div class="flex-center">
	                            <div class="list-text">
	                                <h3>Team :</h3>
	                            </div>
	                        </div>
	                        <div class="ml-auto flex-center">
	                            <div class="list-text">
	                                <h3>{{userData.team}}</h3>
	                            </div>
	                        </div>
	                    </mat-list-item>
	                    <mat-divider></mat-divider>

	                    <mat-list-item>
	                        <div class="flex-center">
	                            <div class="list-text">
	                                <h3>Role</h3>
	                            </div>
	                        </div>
	                        <div class="ml-auto flex-center">
		                        <select  id="role" name="role"  >
						          <option>Select Role </option>
						          <option ng-selected="userData.role == Admin" value="Admin">Admin</option>
						          <option ng-selected="userData.role == Manager"  value="Manager">Manager</option>
						          <option ng-selected="userData.role == User"  value="User">User</option>
						          <option ng-selected="userData.role == Viewonly"  value="Viewonly">Viewonly</option>
						        </select>
					        </div>
	                    </mat-list-item>
	                    <mat-divider></mat-divider>

	                    <mat-list-item>
	                        <div class="flex-center">
	                            <div class="list-text">
	                                <h3>Status</h3>
	                            </div>
	                        </div>
	                        <div class="ml-auto flex-center">
		                        <select  id="status" name="status"  >
						          <option value="1">Active</option>
						          <option value="0">InActive</option>
						        </select>
					    	</div>
	                    </mat-list-item>
	                    <mat-divider></mat-divider>


	                </mat-list>
	            </mat-card>
	        </mat-grid-tile>

	    </mat-grid-list>
	</div> -->
				<div class="form-group">
					<button class="btn btn-primary btn-block">submit</button>
				</div>

				<div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
					failed!<br />{{ errorMessage }}
				</div>
			</form>
		</mat-card-content>
	</mat-card>
</div>