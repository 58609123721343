import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { CustomerinfoDialogComponent } from '../customerinfo-dialog/customerinfo-dialog.component';
import { HistoryPopupComponent } from '../history-popup/history-popup.component';
@Component({
  selector: 'app-data-summary',
  templateUrl: './data-summary.component.html',
  styleUrls: ['./data-summary.component.scss']
})
export class DataSummaryComponent implements OnInit {
  displayedColumns =
  ['checkbox','custid','qty','itemid','txndate', 'ordervalue','prodname', 'itemstatus', 'ordertype', 'invoiceupload', 'suppname', 'suppcontract', 'supplier','star'];
  dataSource = ELEMENT_DATA;

  constructor(public dialog: MatDialog) { }
  historyInfoDialog() {
    const dialogRef = this.dialog.open(HistoryPopupComponent,{
      width: '100%',
      backdropClass: 'HistoryBackdropClass',
      panelClass: 'custom-popup-1'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

    });
  }

  customerInfoDialog() {
    const dialogRef = this.dialog.open(CustomerinfoDialogComponent,{
      width: '100%',
      backdropClass: 'customerInfoBackdropClass',
      panelClass: 'custom-popup-1'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit() {
  }

}

export interface PeriodicElement {
  checkbox: any;
  custid: any;
  qty: any;
  itemid:any;
  txndate:any;
  ordervalue:any;
  prodname:any;
  itemstatus:any;
  ordertype:any;
  invoiceupload:any;
  suppname:any;
  suppcontract:any;
  supplier:any;
  // star:any;
}



const ELEMENT_DATA: PeriodicElement[] = [
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
  {checkbox: '', custid: '1100015',qty: '08', itemid: '922337', txndate: '14-01-2021',ordervalue: 'currency: +400',prodname: 'MSN2VL8OZSED9L',itemstatus: 'Verified',ordertype: 'VMI',invoiceupload: 'Yes (Tax Invoice)',suppname: 'Mogli India Pvt…',suppcontract: '9876543210',supplier: 'D-180, Sector 10, Noida'},
];

