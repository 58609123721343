export const environment = {

  production: false,

 

 

  URLS: {

 

    "OMS_Gateway": 'https://omsgw-qa.moglilabs.com/', //'https://omsgw.moglix.com/',

    "OMS_BASEURL": 'https://omsapiqa.moglilabs.com/' ,//'https://omsapi.moglix.com/',

    "OMS_FINANCE": 'https://omsfinanceqa.moglilabs.com/', //'http://omsfinance.moglix.com',

   

 

    // omsfinanceqa.moglilabs.com

//     omsfinanceqa.moglilabs.com  - qa env

//  - prod env

 

 

  },

  MICROSOFT_LOGIN: {

    clientSecret: 'QcT8Q~WHVASQ~n5_dqWAwnKhgCC4rO4XRbXB1a47',

    clientId: 'bdc11700-7bfb-46d7-b3fb-459d26c353ff',

    tenantId: '421e9584-87ff-424f-95b8-1bf46b70db99'

 

 

 

  }

};
