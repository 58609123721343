<mat-dialog-actions align="end">
	<button  style="pointer-events: auto; cursor: pointer;" mat-button mat-dialog-close class="close-btn"><span class="material-icons"> close</span></button>
</mat-dialog-actions>
<div class="wrapper"  style="pointer-events: auto; cursor: pointer;">
	<div>
		<mat-dialog-content class="mat-typography modal-raduis">
			<div class="flex-center justify-between">
				<h1 class="modal-heading m-0">Update Item Status </h1>
			</div>
			<h3 class="modal-sub-heading"> Item ID: <span class="ml-5"> {{itemAction?.itemId}}</span> </h3>
			<h3 class="modal-sub-heading"> Current State: <span class="ml-5">{{itemAction?.itemStatus}} </span> </h3>
			<h3 class="modal-sub-heading"> Product Name: <span class="ml-5"> {{itemAction?.productName}}</span> </h3>
			<h3 class="modal-sub-heading"> Product Msn: <span class="ml-5">{{itemAction?.productRef}}</span></h3>
		</mat-dialog-content>
		<div>
			<h4>Next allowed states:</h4>
			<select #selectedData [(ngModel)]="selectedNextStatus" (ngModelChange)="enableOrNot()">
				<option value="0" selected="true" disabled="disabled">-- SELECT OPTION --</option>
				<option *ngFor="let menulist of options" [ngValue]="menulist">{{menulist.name}}</option>
			</select>
		</div>
	</div>
	<div>
		<div class="productdetailrow bgwhite clearfix" *ngIf="selectedNextStatus">
			<form [formGroup]="statusFOrm">
				<div class="mou-formarea clearfix">
					<div class="col-md-12 pl-0">
						<div class="infofield_row clearfix" style="display: flex;padding-top: 30px;">
						<div class="halffield" *ngIf="showWareHouseList">
							<div class="inputfield">
										<label>Warehouse</label>
							<select formControlName="warehouseId" >
								<option value="" selected="true" disabled="disabled">-- SELECT --</option>
								<option value="32">Dubai</option>
								<!-- <option value="46">Abu Dhabi</option> -->
								<option value="121">Test UAE</option>
							</select>
						</div>
						</div>
		
						<div class="halffield" *ngIf="showSupplierList">
							<div class="inputfield">
										<label>Suppliers</label>
							<select formControlName="supplierId" >
								<option value="" selected="true" disabled="disabled">-- SELECT --</option>
								<option *ngFor="let supplier of supplierInfoList" [ngValue]="supplier.supplierId"  >Name: {{supplier.supplierName}} - TP: {{supplier.transferPrice}} - StockFlag: {{supplier.outOfStockFlag}} </option>
								
							</select>
						</div>
						</div>
		
						<div class="halffield" *ngIf="showLogisticList">
							<div class="inputfield">
										<label>Logistic Partner</label>
							<select formControlName="shipperId" >
								<option value="" selected="true" disabled="disabled">-- SELECT --</option>
								<option value="5">Moglix</option>
								<option value="15">Holisol</option>
								<option value="31">Self Pickup</option>
							</select>
						</div>
						</div>
		
						<div class="halffield" *ngIf="showLogisticList">
							<div class="inputfield ">
											<label>Logistic Awb</label>
											<input type="text" formControlName="shipperAwb" placeholder="" />
						</div>
						</div>
		
						<div class="halffield" *ngIf="showPaymentTransaction">
									<div class="inputfield">
										<label>Transactions Ref</label>
										<input type="text" formControlName="transactionId" placeholder="Customer prepaid reference" />
									</div>
							</div>
		
							<div class="halffield" *ngIf="showShipmentDetail">
									<div class="inputfield">
										<label>Packet Dimension</label>
										<input type="text" formControlName="length" placeholder="Length in cm" />
										<input type="text" formControlName="width" placeholder="Width in cm" />
										<input type="text" formControlName="height" placeholder="Height in cm" />
										<input type="text" formControlName="weight" placeholder="Weight in grams" />
									</div>
							</div>
		
							<div class="halffield" *ngIf="showStatusDate">
									<div class="inputfield date">
										<label>Status Date<em>*</em></label>
										<input type="date" formControlName="statusDate" placeholder="dd/mm/yyyy">
										<h6 class="text-danger" *ngIf="statusFOrm.get('pickupDate').hasError('required') && 
											statusFOrm.get('pickupDate').touched">
											Please select the Date
										</h6>
									</div>
							</div>
							
							<div class="halffield" *ngIf="showRemarks">
									<div class="inputfield">
										<label>Remarks</label>
										<input type="text" formControlName="remark" placeholder="Please Enter Remarks" />
									</div>
							</div>


							<div *ngIf="showPodupload">
									<label>Upload Pod Image</label>
									<div>
									  <div class="inputfield fileuploadinput"
										style="padding-left: 15px; padding-right: 10px; float: left; width: 35%;">
										<div class="fileup">
										  <span *ngIf='selecedFile ==null '
											[ngClass]="selecedFile==null ? 'filecount fileInputDisable': 'filecount'">Select
											Image</span>
										  <!--  <span [ngClass]="selecedFile!=null ? 'filecount fileInputDisable': 'filecount'">{{selecedFile?.name}}</span> -->
										  <input #userFile style="position: unset;" class="file" type="file" (change)=onFileSelected($event)>
											
										  <span class="upicon">
										  </span>
										</div>
										<!-- <div *ngIf='selecedFile !=null' class="uploadedfileswrap">
										  <span class="uploadedfile">
											<span *ngIf='selecedFile!=null'>{{selecedFile?.name}}<mat-icon (click)="deleteFile()">clear</mat-icon></span>
										  </span>
										</div> -->
										
									  </div>
									</div>
									</div>
								 
							  



		
						</div>
					</div>
				</div>
			</form>
			<div class="rightactions" style="text-align: inherit;" *ngIf="showRemarks || showInstructions || showPickupDate">
				<button class="v-btn submit" (click)="upDateStatus()">Submit</button>
			</div>
		</div>
	</div>
</div>