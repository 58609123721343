import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FiltersComponent } from './filters/filters.component';
import { DataSummaryComponent } from './data-summary/data-summary.component';
import { MaterialComponentsModule } from './core/material-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { MegaMenuComponent } from './mega-menu/mega-menu.component';
import { HistoryPopupComponent } from './history-popup/history-popup.component';
import { CustomerinfoDialogComponent } from './customerinfo-dialog/customerinfo-dialog.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { routing } from './app-routing';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { DefaultpageComponent } from './defaultpage/defaultpage.component';
import { ProfileComponent } from './profile/profile.component';
import { AddmenuComponent } from './addmenu/addmenu.component';
import { EditaccessComponent } from './editaccess/editaccess.component';
import { UpdateaccessComponent } from './updateaccess/updateaccess.component';
import { DialogComponent } from './dialog/dialog.component';
import { NgxFlagPickerModule } from 'ngx-flag-picker';
import { ActionPopupComponent } from './action-popup/action-popup.component';
import { ItemActionPopupComponent } from './item-action-popup/item-action-popup.component';
import { LoaderModule } from './loader/loader.module';
import { MicrosoftLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FiltersComponent,
    DataSummaryComponent,
    HomeComponent,
    MegaMenuComponent,
    HistoryPopupComponent,
    CustomerinfoDialogComponent,
    SignupComponent,
    LoginComponent,
    DefaultpageComponent,
    ProfileComponent,
    AddmenuComponent,
    EditaccessComponent,
    UpdateaccessComponent,
    DialogComponent,
    ActionPopupComponent,
    ItemActionPopupComponent,
  ],
  imports: [
    BrowserModule,
    MaterialComponentsModule,
    BrowserAnimationsModule,
    routing,
    FormsModule,
    LoaderModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxFlagPickerModule,
    BsDatepickerModule.forRoot(),
    SocialLoginModule
    
  ],
//   providers: [authInterceptorProviders,
//   //   {
//   //   provide: 'SocialAuthServiceConfig',
//   //   useValue: {
//   //     autoLogin: false,
//   //     providers: [
//   //       {
//   //         id: MicrosoftLoginProvider.PROVIDER_ID,
//   //         provider: new MicrosoftLoginProvider(environment.MICROSOFT_LOGIN.clientId, {
//   //           authority: `https://login.microsoftonline.com/${environment.MICROSOFT_LOGIN.tenantId}/`
//   //         })
//   //       }
//   //     ],
//   //     onError: (err) => {
//   //       console.error(err);
//   //     }
//   //   } as SocialAuthServiceConfig,
//   // }
//   {
//     provide: 'SocialAuthServiceConfig',
//     useValue: {
//       autoLogin: false,
//       providers: [
//         {
//           id: MicrosoftLoginProvider.PROVIDER_ID,
//           provider: new MicrosoftLoginProvider(environment.MICROSOFT_LOGIN.clientId, {
//             authority: `https://login.microsoftonline.com/${environment.MICROSOFT_LOGIN.tenantId}/`
//           })
//         }
//       ],
//       onError: (err) => {
//         console.error(err);
//       }
//     } as SocialAuthServiceConfig,
//   }
// ],
providers: [
  // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: MicrosoftLoginProvider.PROVIDER_ID,
          provider: new MicrosoftLoginProvider(environment.MICROSOFT_LOGIN.clientId, {
            authority: `https://login.microsoftonline.com/${environment.MICROSOFT_LOGIN.tenantId}/`
          })
        }
      ],
      onError: (err) => {
        console.error(err);
      }
    } as SocialAuthServiceConfig,
  }
],
  bootstrap: [AppComponent]
})
export class AppModule { }

