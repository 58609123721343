import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../_services/token-storage.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss']
})
export class MegaMenuComponent implements OnInit {
  currentUser: any;
  menuList : any;
  httpOptions: { headers: any; };
  constructor(private authService: AuthService, private token: TokenStorageService, private router : Router, private matDialog: MatDialog, private http: HttpClient ) { }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    //this.getFeaturesListByuserId();
    this.getMenus_List();
  }

  // getFeaturesListByuserId() {
  //   this.authService.listFeature(this.currentUser.id, this.token.getUser().team, this.token.getUser().role).subscribe(
  //     (data) => {
  //       console.log(data);
  //       if (data.success) {
  //         let list = data.data;
  //         this.menuList = list.filter((element, index)=>{
  //           if(element.featureParent == null){
  //             return element;
  //           }
  //         })
  //       }
  //       console.log("====this.menuList", this.menuList);
  //     },
  //     err => {
  //      console.log(err.error.message);
  //     }
  //   );
  // }



  getMenus_List() {
    const AUTH_API = environment.URLS.OMS_Gateway + 'api/auth/';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token.getToken()
      })
    };
    this.http.get(AUTH_API + `myfeature/list`, this.httpOptions)
      .subscribe((data) => {
        if (data['success']) {
          let list = data['data'];
          this.menuList = list.filter((element, index) => {
            if (element.featureParent == null) {
              return element;
            }
          })
        }
      },
        err => {
          console.log(err.error.message);
        })
  }


  navigateTo(item){
    if(item.url == '/omsapi/procurement/order/list'){
      this.router.navigateByUrl('purchase-order');
    }else if(item.url == '/omsapi/oms/order/listing'){
      this.router.navigateByUrl('customer-order');
    }else{
     this.router.navigateByUrl(item.url)
    //  .then(() => {
    //   window.location.reload();
    //  })
    }
  }

}