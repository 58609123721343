import { Component, OnInit } from '@angular/core';
import { CommonService } from './_services/common.service';
import { TokenStorageService } from './_services/token-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit { 
  title = 'oms-app';
  private roles: string[] = [];
  isLoggedIn = false;
  username?: string;

  constructor(private tokenStorageService: TokenStorageService, public _commonService: CommonService) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      

      this.username = user.username;
    }
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.localStorage.clear();
  }

  // events: string[] = [];
  // opened: boolean = true;
  // isExpanded: boolean;

  // open() {
  //   this.opened = true;
  // }

  // close() {
  //   this.opened = false;
  // }

  // navigateTo(item){
  //   this.router.navigateByUrl(item.url);
  // }

}