import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../_services/token-storage.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  currentUser: any;
  isLoggedIn = false;
  country :any;
  dataSource: any;
  menuList_data : any = [];
  constructor(private token: TokenStorageService, private dialog: MatDialog, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    this.country = localStorage.getItem('CountryCode');
    if(this.country == null || this.country == 'undefined'){
      this.openDialog();
    }
    this.isLoggedIn = true;
    // intiall call
    this.getFeaturesListByuserId();
   // this.getMenus_List();
  }

  // getMenus_List(){
  //   this.authService.getProfileMenus().subscribe((res)=>{
  //     console.log("========res", res);
  //   })
  // }

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent,{
      data:{
        message: 'Please select country to continue',
        buttonText: {
          ok: 'Submit',
          cancel: 'No'
        },
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    });
  }

  getFeaturesListByuserId() {
    this.authService.listFeature(this.currentUser.id, this.token.getUser().team, this.token.getUser().role).subscribe(
      (data) => {
        console.log(data);
        if (data.success) {
          this.dataSource = data.data;
          this.menuList_data = this.dataSource.filter((element, index)=>{
            if(element.featureParent == null){
              return element;
            }
          })
        }
      },
      err => {
       console.log(err.error.message);
      }
    );
  }

  navigateTo(item){
    if(item.url == '/omsapi/procurement/order/list'){
      this.router.navigateByUrl('purchase-order');
    }else{
    this.router.navigateByUrl(item.url);

    }
  }
}