<div class="userListWrap" *ngIf="isMenuListResponse">
  <!-- <h2 class="main-sub-heading">User's List</h2> -->
  <mat-card>
    <mat-card-header>
      <mat-card-title>Users List</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p class="lable">Total List Count: {{dataSource.length}}</p>
      <table>
        <thead>
          <tr>
            <th *ngFor="let column of displayedColumns ">{{column}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of dataSource">
            <td>{{row.id}}</td>
            <td>{{row.name}}</td>
            <td>{{row.email}}</td>
            <td>{{row.team}}</td>
            <td>{{row.role}}</td>
            <td><span *ngIf="row.status == 1">Active</span><span *ngIf="row.status == o">InActive</span></td>
            <!-- td > <div *ngif="row.status == 1; else elseBlock" >Acive </div> 
                <ng-template #elseBlock>InActive</ng-template>
              </td> -->
            <td style="padding-top: 10px; padding-bottom: 10px;">
              <!-- <a  routerLink="/updateaccess/{{row.id}}"> -->
              <div>
                <button class="ml-auto" mat-raised-button color="primary" (click)="addUserinfoSession(row)">Edit Access
                </button>
              </div>
              <!-- </a> -->
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
  </mat-card>
</div>