<div class="col-md-12">
  <div class="card card-container">
    <mat-card>
      <mat-card-content class="mat-card-content_Add_menu">
        <form class="form-add-menu" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
          <div class="form-group">
            <label for="menuParent">Parent Menu</label>
            <select class="form-control" id="menuParent" name="menuParent" [(ngModel)]="form.menuParent" [ngModelOptions]="{standalone: true}">
              <option>Select Menu</option>
              <option *ngFor="let menulist of dataSource"  [value]="menulist.featureName">{{menulist.featureName}}</option>
            </select>
          </div> 
          <div class="form-group">
            <label for="menuName">Menu Name<em>*</em></label>
            <input type="text" class="form-control" name="menuName" [(ngModel)]="form.menuName" required
              #menuName="ngModel" />
            <div class="alert-danger" *ngIf="menuName.errors && f.submitted">
              <div *ngIf="menuName.errors.required">Menu Name is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="menuUrl">Menu url<em>*</em></label>
            <input type="text" class="form-control" name="menuUrl" [(ngModel)]="form.menuUrl" required
              #menuUrl="ngModel" />
            <div class="alert-danger" *ngIf="menuUrl.errors && f.submitted">
              <div *ngIf="menuUrl.errors.required">menuUrl is required</div>
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-primary btn-block">Submit</button>
          </div>
          <div class="alert alert-warning" *ngIf="f.submitted && isResponseSuccess && !isSuccessful">
            Creation failed!<br />{{ errorMessage }}
          </div>
        </form>
        <div class="alert alert-success" *ngIf="isSuccessful">
          {{ errorMessage }}.
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="col-md-12 mat-card_feature" style="padding-bottom: 20px;" *ngIf="isMenuListResponse">
  <br>
  <!-- <h2 class="main-sub-heading">Feature List</h2> -->
  <mat-card>
    <mat-card-header>
      <mat-card-title>Feature List</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p class="lable">Total List Count: {{featureList.length}}</p>
      <table>
        <thead>
          <tr>
            <th *ngFor="let column of displayedColumns ">{{column}}</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of featureList">
            <td>{{row.id}}</td>
            <td>{{row.featureName}}</td>
            <td><span *ngIf="row.status == 1">Active</span><span *ngIf="row.status == o">InActive</span></td>
            <td style="padding-top: 5px;padding-bottom: 5px;">
              <div style="display: flex;">
                <button type="button" *ngIf="row.status != 1"  mat-raised-button color="primary" (click)="updateMenu(1, row.id)">Enable</button>
                <button type="button" *ngIf="row.status == 1" mat-raised-button color="warn" (click)="updateMenu(0, row.id)">Disable</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
  </mat-card>
  <!-- <div class="inner-cpsticky example-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef>   ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="featureName" sticky>
        <th mat-header-cell *matHeaderCellDef>  Feature Name </th>
        <td mat-cell *matCellDef="let element"> {{element.featureName}}  </td>
      </ng-container>

      <ng-container matColumnDef="status" sticky>
        <th mat-header-cell *matHeaderCellDef>  Status </th>
        <td mat-cell *matCellDef="let element"> {{element.status}}  </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="historyDisplayedCloumn"></tr>
      <tr mat-row *matRowDef="let row; columns: historyDisplayedCloumn;"></tr>
    </table>
  </div> -->
  <!-- <mat-grid-list class="custom-grid-1" cols="2" rowHeight="2:1">
      <mat-grid-tile>
            <mat-card class="card-style-1 ml-2p">
                <table mat-table class="mat-elevation-z8 table ">

                    <thead>
                      <tr>           
                        <th mat-header-cell *ngFor="let column of displayedColumns ">{{column}}</th>
                      </tr>
                    </thead>

                    
                </table>
            </mat-card>
        </mat-grid-tile> -->
      <!-- <mat-grid-tile>       
            <mat-card class="card-style-1 mr-2p">
                <mat-list>
                    <mat-list-item>
                        <div class="flex-center">
                            <div class="count-bagde badge-color-green">10</div>
                            <div class="list-text">
                                <h3>Id</h3>
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>
                        <div class="flex-center">
                            <div class="count-bagde badge-color-green">10</div>
                            <div class="list-text">
                                <h3>Feature Name</h3>
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>
                        <div class="flex-center">
                            <div class="count-bagde badge-color-green">10</div>
                            <div class="list-text">
                                <h3>Status</h3>
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </mat-list>
        </mat-card>
      </mat-grid-tile> -->
  <!-- </mat-grid-list> -->
</div>
