import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../_services/auth.service';
import { CommonService } from '../_services/common.service';
import { Utility } from '../utility';
import saveAs from 'file-saver';
import { ngxCsv } from 'ngx-csv/ngx-csv';




@Component({
  selector: 'app-bulkorder-upload',
  templateUrl: './bulkorder-upload.component.html',
  styleUrls: ['./bulkorder-upload.component.scss']
})
export class BulkorderUploadComponent implements OnInit {
  fileToUpload: File | null = null;
  selecedFile: File = null;
  name = 'Angular 6';
  status: any[];
  fileName:string = "bulk_upload_sample";
  fileGroup:any;
  fileNameGroup:any;

  constructor(public dialog: MatDialog,private authService: AuthService,private _commonService: CommonService) { }

  ngOnInit(): void {
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
}


bulkOrderUpload() {
  let formDat: FormData = new FormData();
  if(this.fileGroup != null){
      formDat.append('uploadBulkOrderCsv', this.fileGroup, this.fileGroup.name);
  }
  this.authService.bulkUpload(formDat).subscribe(response => {
    //alert("file uploaded successfully");
    console.log("====response", response);
  
    if(response.success && response.code == 200){
      alert(response.message);
      //console.log("====response", response)
      // saveAs(response['data'][0], `PushedOrder.csv`)
     this.csv_Template(response['data']);
    }
    else{
      alert(response.message);
    } 


    }, error => {
      console.log(error);
      alert("Please procees with valid File")
    });
}

csv_Template(data){
  let csvData = data;
  let options = { 
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true, 
    showTitle: true,
   title: 'MeaBulkOrderResponse',
    useBom: true,
    headers:  Object.keys(csvData[0])
    
  };
  new ngxCsv(csvData, `MeaBulkOrderResponse`, options);
}

downloadCsv(data) {
  const d = new Date();
  const fileName = d.getDate() +"-"+ (d.getMonth() + 1) + "-" + d.getFullYear() + ".csv";
  const link = document.createElement("a");
  let blob = new Blob([data], { type: 'application/csv' });
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('visibility', 'hidden');
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

//download csv
downloadCSV() {
 
  var data = [
    {
      1:'1234',
2:'1',
3:'IRewards Solutions Pvt Ltd',
4:'data@irewardsindia.com',
5:'27AADCI6603H1ZO',
6:'449',
7:'449',
8:'6',
9:'payment_pending',
10:'49',
11:'49',
12:'data@irewardsindia.com',
13:'BIHAR',
14:'852122',
15:'murli kumar',
16:'Mishra',
17:'SHIV MACHINARYGOUSALA CHOUK MURLIGANJ, MADHEPURA',
18:'PURNIA',
19:'Landmark123',
20:'7250146568',
21:'27AADCI6603H1ZO',
22:'data@irewardsindia.com',
23:'Maharashtra',
24:'400614',
25:'IRewards Solutions Pvt Ltd',
26:'abc',
27:'B- 403 The Great Eastern Chambers, [Sector 11], Near To PNB Bank, CBD Belapur',
28:'Thane',
29:'2241271220',
30:'27AADCI6603H1ZO',
31:'MSN2VSFQOFC5B7',
32:'MSN2VSFQOFC5B7',
33:'Bajaj LED Smart Glow Torch (Vary In Colour)',
34:'449',
35:'449',
36:'2',
37:'49',
38:'49',
39:'18',
40:'380.51',
41:'11',
42:'2'

    },
  
  ];

  var options = {
    title: 'Sample Bulk Upload',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    useBom: true,
    headers: ['customerId','isGstinvoice','companyName','companyEmail','companyTRN','totalPaid',
    'orderTotal','paymentMode','paymentStatus','order_discounts','order_shipping','shipping_email_id', 
    'shipping_emirate','shipping_PO_Box','shipping_firstname','shipping_lastname','shipping_address',
    'shipping_city','shipping_landmark','shipping_phone_mobile', 'shipping_TRN','billing_email_id',
    'billing_emirate','billing_PO_Box','billing_firstname','billing_lastname','billing_address',
    'billing_city','billing_phone_mobile','billing_TRN','product_id','product_reference',
    'product_name','product_price','product_mrp','product_quantity','itemShipping',
    'itemDiscount', 'purchaseTax','priceWithoutTax','uniqueId', 'orderProperty']
  };

 // new Angular2Csv(data, this.fileName, options);
}

onFileChangedGroup(event) {

  this.fileGroup = event.target.files[0];

  this.fileNameGroup = this.fileGroup.name;

  let flag = Utility.fileValidation(this.fileGroup);

  if (!flag) {

    this.fileGroup = null;

    alert("Please upload file having extensions .csv only.");

  } else if(this.fileGroup.size < 22) {

    alert("Please upload file with proper data");

  } else {

    this.bulkOrderUpload();

  }

}


clearStackGroup(event) {

  event.target.value = null;

  this.fileGroup = null;

  this.fileNameGroup = null;

}


downloadTemplate() {
  let groupListDetails = [];

  

      let body = {

        isGstinvoice:'1',
        companyName:'IRewards Solutions Pvt Ltd',
        companyEmail:'data@irewardsindia.com',
        companyTRN:'27AADCI6603H1ZO',
        totalPaid:'449',
        orderTotal:'449',
        paymentMode:'6',
        paymentStatus:'payment_pending',
        order_discounts:'49',
        order_shipping:'49',
        shipping_email_id:'data@irewardsindia.com',
        shipping_emirate:'BIHAR',
        shipping_PO_Box:'852122',
        shipping_firstname:'murli kumar',
        shipping_lastname:'Mishra',
        shipping_address:'SHIV MACHINARYGOUSALA CHOUK MURLIGANJ, MADHEPURA',
        shipping_city:'PURNIA',
        shipping_landmark:'Landmark123',
        shipping_phone_mobile:'7250146568',
        shipping_TRN:'27AADCI6603H1ZO',
        billing_email_id:'data@irewardsindia.com',
        billing_emirate:'Maharashtra',
        billing_PO_Box:'400614',
        billing_firstname:'IRewards Solutions Pvt Ltd',
        billing_lastname:'abc',
        billing_address:'B- 403 The Great Eastern Chambers, [Sector 11], Near To PNB Bank, CBD Belapur',
        billing_city:'Thane',
        billing_phone_mobile:'2241271220',
        billing_TRN:'27AADCI6603H1ZO',
        product_id:'MSN2VSFQOFC5B7',
        product_reference:'MSN2VSFQOFC5B7',
        product_name:'Bajaj LED Smart Glow Torch (Vary In Colour)',
        product_price:'449',
        product_mrp:'449',
        product_quantity:'2',
        itemShipping:'49',
        itemDiscount:'49',
        purchaseTax:'5',
        priceWithoutTax:'380.51',
        uniqueId:'11',
        orderProperty:'2'

      }

      groupListDetails.push(body);

      const headers = ['isGstinvoice','companyName','companyEmail','companyTRN','totalPaid',
      'orderTotal','paymentMode','paymentStatus','order_discounts','order_shipping','shipping_email_id', 
      'shipping_emirate','shipping_PO_Box','shipping_firstname','shipping_lastname','shipping_address',
      'shipping_city','shipping_landmark','shipping_phone_mobile', 'shipping_TRN','billing_email_id',
      'billing_emirate','billing_PO_Box','billing_firstname','billing_lastname','billing_address',
      'billing_city','billing_phone_mobile','billing_TRN','product_id','product_reference',
      'product_name','product_price','product_mrp','product_quantity','itemShipping',
      'itemDiscount', 'purchaseTax','priceWithoutTax','uniqueId', 'orderProperty'];

      const csvData = Utility.convertToCSV(groupListDetails,headers);

  // let csvData = Utility.convertToCSV(["1,3,4,5,6,7,2"],["category code","brand name","group name","product id","parent","map"]);

  // csvData = csvData.substring(6);

  Utility.downLoadFile(csvData, 'Bulk-Order');

}

}
