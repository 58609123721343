<div class="header" style="margin: 25px;">

 <div class="uploadaction2">

  <button  mat-button class="verify-btn1">Bulk Order Upload</button>

  <input  #attachments type="file" title=""  (click)="clearStackGroup($event)" (change)="onFileChangedGroup($event)" name="attachments" #fileInput>

</div>
<div>
  <span title="Download Bulk Order Template" style="padding-left: 0px;margin-top: 200px;">

    <img class="icon-class" (click)="downloadTemplate()" src="../assets/images/csvDownload.svg" />
    
  </span>
  
</div>
</div>


