import { Component, OnInit } from '@angular/core';

import { TokenStorageService } from '../_services/token-storage.service';
import { AuthService } from '../_services/auth.service';


import {MatDialog} from '@angular/material/dialog';
import { CustomerinfoDialogComponent } from '../customerinfo-dialog/customerinfo-dialog.component';
import { HistoryPopupComponent } from '../history-popup/history-popup.component';

import {Router} from '@angular/router';

@Component({
  selector: 'app-addmenu',
  templateUrl: './addmenu.component.html',
  styleUrls: ['./addmenu.component.scss']
})
export class AddmenuComponent implements OnInit {
	isSuccessful = false;
	isResponseSuccess = false;
	displayedColumns = ['id', 'featureName', 'status'];
	dataSource : any;
	featureList: any = [];
	isMenuListResponse = false;
	isMenuListError = false;

	errorMessage = '';
	constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router, public dialog: MatDialog) { }

	historyInfoDialog() {
		const dialogRef = this.dialog.open(HistoryPopupComponent, {
			width: '100%',
			backdropClass: 'HistoryBackdropClass',
			panelClass: 'custom-popup-1'
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`);

		});
	}

	customerInfoDialog() {
		const dialogRef = this.dialog.open(CustomerinfoDialogComponent, {
			width: '100%',
			backdropClass: 'customerInfoBackdropClass',
			panelClass: 'custom-popup-1'
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`);
		});
	}

	

	ngOnInit(): void {
		console.log(this.tokenStorage.getUser());
		this.get_Feature_List();
	}

	get_Feature_List() {

		this.authService.get_all_featureslist('').subscribe(
			(data) => {
				console.log(data);
				if (data['success']) {
					this.isMenuListResponse = true;
					this.featureList = data['data'];
					this.dataSource = data['data'].filter((element)=>{
						if(element.featureParent == null){
						  return element;
						}
					  });
					console.log(this.dataSource[0].featureName);
				}

			},
			(err) => {
				this.errorMessage = err.error.message;
				this.isMenuListError = true;
			}
		);
	}

	form: any = {    
	    menuName: null,
	    menuUrl: null,
		menuParent : null    
	};

	onSubmit(): void {
		const { menuParent, menuName, menuUrl } = this.form;
		console.log(menuParent);
		this.authService.createFeature(menuParent, menuName, menuUrl).subscribe(
			data => {
				console.log(data);
				if (data.success) {
					this.isSuccessful = true;
					this.errorMessage = data.message;
					this.get_Feature_List();
				}
				else {
					this.isResponseSuccess = true;
					this.errorMessage = data.message;
				}
			},
			err => {
				this.errorMessage = err.error.message;
				this.isResponseSuccess = true;
			}
		);
	}

	//=== disable or enable feature list menu based on status =======
	updateMenu(status, featureId){
		let requestBody = {
			status : status,
			featureId : [featureId]
		}
		console.log('requestBody', requestBody);
		this.authService.editFeature_List_Menu(requestBody).subscribe((res)=>{
			if(res && res.code == 200){
				this.get_Feature_List();
			}
		},(err)=>{
			console.log("internal server error", err);
		})
	}
}



export interface PeriodicElement {
  id: any;
  featureName: any;
  status: any;
}