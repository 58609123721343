import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { FormControl, FormGroup} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { CommonService } from '../_services/common.service';

@Component({
  selector: 'app-action-popup',
  templateUrl: './action-popup.component.html',
  styleUrls: ['./action-popup.component.scss']
})
export class ActionPopupComponent implements OnInit {

    
constructor(private _commonService: CommonService, private authService : AuthService,  public dialogRef: MatDialogRef<ActionPopupComponent>) { }
  
  newStateTransitionMap=[{
    SUPPLIER_ASSIGNED: [{name:'PICKUP_SCHEDULED', value: 20}, {name: 'PO_CANCELLED', value: 80}],
    PICKUP_DONE:[{name: 'QC_FAIL', value: 70}, {name: 'QC_PASS', value : 60}],
    PICKUP_PENDING : [{name: 'PICKUP_SCHEDULED', value:20}, {name:'OUT_FOR_PICKUP' , value: 30}, {name:'PO_CANCELLED' , value: 80}],
    PICKUP_SCHEDULED: [{name: 'OUT_FOR_PICKUP', value:30},{name:'PICKUP_PENDING', value:40}, {name:'PO_CANCELLED', value:80}],
    OUT_FOR_PICKUP: [{name: 'PICKUP_PENDING', value: 40}, {name: 'PICKUP_DONE', value: 50}],
    QC_FAIL : [{name:'QC_PASS' , value: 60}, {name:'PO_EXCHANGE' , value:90}],
    QC_PASS: [{name:'PO_FULFILLED' , value: 100}],
    PO_EXCHANGE: [{name: 'PICKUP_SCHEDULED', value: 20}],
    PO_FULFILLED: [{name:'PO_RETURN_INTITATED' , value:110}],
    PO_RETURN_INTITATED : [{name: 'PO_WAREHOUSE_RETURN', value: 120}, {name: 'PO_SUPPLIER_RETURN_INTITATED', value: 150}],
    PO_RETURN_QCFAIL: [{name: 'PO_RETURN_QCPASS', value: 130}],
    PO_RETURN_QCPASS : [{name: 'PO_SUPPLIER_RETURN_INTITATED', value: 150}],
    PO_SUPPLIER_RETURN_INTITATED : [{name: 'PO_SUPPLIER_RETURN', value: 200}],
    PO_WAREHOUSE_RETURN: [{name: 'PO_RETURN_QCPASS', value : 130}],
    PO_SUPPLIER_RETURN: [],
    PO_CANCELLED: [],
    DROPSHIP_ACTIVATED : [],
    FAILED_SUPPLIER: [],
  }];
  itemAction :  any ;
  itemStatusAction=""
  showRemarks: boolean = false;
  showPickupDate: boolean = false;
  showInstructions : boolean = false;
  selectedNextStatus: any =0;
  statusFOrm  =  new FormGroup({
    pickupDate :  new FormControl(''),
    instructions: new FormControl(''),
    remarks: new FormControl('')
  })
  options : any;
  userDetail:any;

  ngOnInit( ): void {
    this.authService.actionItem.subscribe((actionItem)=>{
      this.itemAction = actionItem;
      this.itemStatusAction= this.itemAction?.poStatus;
      this.options = this.newStateTransitionMap[0][this.itemStatusAction]
    })
  }

  upDateStatus(){
      
      if(this.showPickupDate == true && this.statusFOrm.get('pickupDate').value == ''){
        alert("Please add Pickup Date");
        return 
      }

      this.userDetail = JSON.parse(window.localStorage.getItem("auth-user") );


      let req = {
                  "userEmail": this.userDetail.email,
                  "userId": this.userDetail.id ,
                  "poUniqueId" : this.itemAction.poUniqueId,  
                  "poStatus" : this.selectedNextStatus.value
                  };
      
      Object.keys( this.statusFOrm.value).forEach(element => {
        if(this.statusFOrm.value?.[element] != ''){
          if(element == 'pickupDate'){
            req['pickupScheduled'] =  this.statusFOrm.value?.[element];
          }
          req[element] = this.statusFOrm.value?.[element];
        }
      });
      this._commonService.showLoader();
     this.authService.update_Purchase_Order(req).subscribe((response)=>{
        if(response.code == 200 && response.success){
          alert("Po Status Successfully update");
          this.dialogRef.close();
        }else{
          alert(response.message);
          this.dialogRef.close();
        }
        this._commonService.hideLoader();
     }, (err)=>{
        alert(err.error.error);
        this._commonService.hideLoader();
     });

  }

  enableOrNot(){
     this.showRemarks= false;
      this.showPickupDate = false;
      this.showInstructions  = false;
    if(this.selectedNextStatus.name == 'PICKUP_SCHEDULED'){

      this.showInstructions = true; this.showPickupDate = true; this.showRemarks = true;

    }else if(this.selectedNextStatus.name =='PICKUP_PENDING'){
       this.showPickupDate = true; this.showRemarks = true
     
    }else if(this.selectedNextStatus.name == 'OUT_FOR_PICKUP' ||  this.selectedNextStatus.name == 'PO_SUPPLIER_RETURN' || this.selectedNextStatus.name == 'PO_FULFILLED' || this.selectedNextStatus.name == 'QC_PASS' || this.selectedNextStatus.name =='PICKUP_DONE' ||this.selectedNextStatus.name == 'OUT_FOR_PICKUP'){
      
      this.showRemarks = true
     
    }else if(this.selectedNextStatus.name =='QC_FAIL'){

      this.showInstructions = true;  this.showRemarks = true
        
    }else if(this.selectedNextStatus.name == 'PO_CANCELLED'){

      this.showInstructions = true;  this.showRemarks = true

    } else if(this.selectedNextStatus.name == 'PO_EXCHANGE'){
      this.showInstructions = true;  this.showRemarks = true
   
    }else if (this.selectedNextStatus.name == 'PO_EXCHANGE'){
      this.showInstructions = true; this.showPickupDate = false; this.showRemarks = true
    }else if(this.selectedNextStatus.name == 'PO_RETURN_INTITATED' || this.selectedNextStatus.name == 'PO_WAREHOUSE_RETURN' || this.selectedNextStatus.name == 'PO_RETURN_QCPASS' || this.selectedNextStatus.name == 'PO_RETURN_QCFAIL' || this.selectedNextStatus.name == 'PO_SUPPLIER_RETURN_INTITATED'){
       this.showRemarks = true
    }
  }

}
