<section class="finance-cpstickytable">
  <div class="inner-cpsticky example-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="checkbox" sticky>
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox class="example-margin"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element" sticky>
          <mat-checkbox class="example-margin"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="custid" sticky>
        <th mat-header-cell *matHeaderCellDef> <span class="twoRow">Order /</span> <span class="twoRow">Customer
            ID</span></th>
        <td mat-cell *matCellDef="let element"> <span class="twoRow orderBlue">{{element.custid}} </span> <span
            class="twoRow">3246724</span></td>
      </ng-container>
      <ng-container matColumnDef="qty" sticky>
        <th mat-header-cell *matHeaderCellDef> Qty.</th>
        <td mat-cell *matCellDef="let element">
          {{element.qty}} </td>
      </ng-container>
      <ng-container matColumnDef="itemid" sticky>
        <th mat-header-cell *matHeaderCellDef> Item ID</th>
        <td mat-cell *matCellDef="let element"> {{element.itemid}} </td>
      </ng-container>
      <ng-container matColumnDef="txndate" sticky>
        <th mat-header-cell *matHeaderCellDef> Txn Date <span class="calender material-icons">
            date_range
          </span></th>
        <td mat-cell *matCellDef="let element"> <span class="twoRow dateBlack">{{element.txndate}}</span> <span
            class="twoRow">04:15:26</span> </td>
      </ng-container>
      <ng-container matColumnDef="ordervalue" sticky>
        <th mat-header-cell *matHeaderCellDef> Order Values </th>
        <td mat-cell *matCellDef="let element" class="hoverTooltipBlock">
          {{element.ordervalue}} 
          <!-- <span class="material-icons">info</span> -->
          <div class="hover-tooltip tooptip-style-1">
            <span class="material-icons">info</span>
            <div class="tooltip-msg-box">
              <div class="tooltip-msg-left d-flex flex-column w-50 ">
                <h5 class="text-nowrap">Order Value</h5>
                <ul class="text-nowrap">
                  <li>Order: +350</li>
                  <li>Discount: -0</li>
                  <li>Shipping: +50</li>
                  <li>Total =400</li>
                </ul>
              </div>
              <div class="tooltip-msg-right d-flex flex-column w-50 ">
                <h5 class="text-nowrap">Item Value</h5>
                <ul class="text-nowrap">
                  <li>Price: +350</li>
                  <li>Discount: -0</li>
                  <li>Fix: +0</li>
                  <li>Shipping: +50</li>
                </ul>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="prodname" sticky>
        <th mat-header-cell *matHeaderCellDef> Product Name </th>
        <td mat-cell *matCellDef="let element"> <span class="twoRow prdRed">{{element.prodname}}</span><span
            class="twoRow">Techtonics TECH2042 A3967 Easy…</span> </td>
      </ng-container>
      <ng-container matColumnDef="itemstatus" sticky>
        <th mat-header-cell *matHeaderCellDef [matMenuTriggerFor]="menu1">Item Status <span class="material-icons">
            arrow_drop_down
          </span></th>
        <mat-menu #menu1="matMenu" class="multiple-checkbox-modal">
          <input type="text" class="modal-search" value="Search Status" />
          <div class="multiple-checkbox-section d-flex flex-column mx-15">
            <mat-checkbox class="example-margin">New</mat-checkbox>
            <mat-checkbox class="example-margin">Payment Pending</mat-checkbox>
            <mat-checkbox class="example-margin">Payment Failed</mat-checkbox>
            <mat-checkbox class="example-margin">Assist Convert</mat-checkbox>
            <mat-checkbox class="example-margin">Verification Pending</mat-checkbox>
            <mat-checkbox class="example-margin">Exchange Verified</mat-checkbox>
            <mat-checkbox class="example-margin">Verified</mat-checkbox>
            <mat-checkbox class="example-margin">Choose Supplier</mat-checkbox>
            <mat-checkbox class="example-margin">Failed Supplier</mat-checkbox>
            <mat-checkbox class="example-margin">Supplier Assigned</mat-checkbox>
            <mat-checkbox class="example-margin">Pickup Bandwidth Issue</mat-checkbox>
            <mat-checkbox class="example-margin">Pickup Supplier Issue</mat-checkbox>
            <mat-checkbox class="example-margin">PDC Awaited</mat-checkbox>
            <mat-checkbox class="example-margin">Delayed Inventory Avail</mat-checkbox>
            <mat-checkbox class="example-margin">Pickup Pending</mat-checkbox>
            <mat-checkbox class="example-margin">Out For Pickup</mat-checkbox>
            <mat-checkbox class="example-margin">Instant Inventory Avail</mat-checkbox>
            <mat-checkbox class="example-margin">Logistic Pickup Activate</mat-checkbox>
            <mat-checkbox class="example-margin">Pickup Done</mat-checkbox>
            <mat-checkbox class="example-margin">Inventory Pickup</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
            <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
          </div>

        </mat-menu>
        <td mat-cell *matCellDef="let element"> {{element.itemstatus}} </td>
      </ng-container>
      <ng-container matColumnDef="ordertype" sticky>
        <th [matMenuTriggerFor]="menu2" mat-header-cell *matHeaderCellDef>Order Type <span class="material-icons">
            arrow_drop_down
          </span></th>
        <mat-menu #menu2="matMenu" class="checkbox-dropdown ">
          <div class="checkbox-dropdown-section d-flex flex-column mx-10 my-15">
            <mat-checkbox class="example-margin">Oneship</mat-checkbox>
            <mat-checkbox class="example-margin">Dropship</mat-checkbox>
            <mat-checkbox class="example-margin">VMI</mat-checkbox>

          </div>
        </mat-menu>
        <td mat-cell *matCellDef="let element"> {{element.ordertype}} </td>
      </ng-container>
      <ng-container matColumnDef="invoiceupload" sticky>
        <th mat-header-cell *matHeaderCellDef> Invoice Uploaded <span class="material-icons">
            arrow_drop_down
          </span></th>
        <td mat-cell *matCellDef="let element"> {{element.invoiceupload}} </td>
      </ng-container>
      <ng-container matColumnDef="suppname">
        <th class="common" mat-header-cell *matHeaderCellDef> Supplier Name</th>
        <td class="common" mat-cell *matCellDef="let element"> <span class="twoRow">{{element.suppname}}</span> <span
            class="twoRow">2021-07-01</span> </td>
      </ng-container>
      <ng-container matColumnDef="suppcontract">
        <th class="common" mat-header-cell *matHeaderCellDef> Supplier Contact</th>
        <td class="common" mat-cell *matCellDef="let element"> {{element.suppcontract}} </td>
      </ng-container>
      <ng-container matColumnDef="supplier">
        <th class="common" mat-header-cell *matHeaderCellDef> Supplier</th>
        <td class="common" mat-cell *matCellDef="let element"> {{element.supplier}} </td>
      </ng-container>

      <ng-container matColumnDef="star" stickyEnd>
        <th class="common" mat-header-cell *matHeaderCellDef> </th>
        <td class="common" mat-cell *matCellDef="let element" [matMenuTriggerFor]="invoiceMenu">
          <mat-icon>more_vert</mat-icon>
        </td>
        <mat-menu class="invoiceMenu" #invoiceMenu="matMenu">
          <button (click)="historyInfoDialog()" mat-menu-item>History</button>
          <button (click)="customerInfoDialog()" mat-menu-item>Split </button>
          <button mat-menu-item>Add Comment </button>
          <button mat-menu-item>Download Invoice </button>
          <button mat-menu-item>Packaging </button>
          <button mat-menu-item>Assign To</button>
        </mat-menu>
      </ng-container>



      <!-- Header row first group -->
      <ng-container matColumnDef="header-row-first-group" sticky>
        <th mat-header-cell *matHeaderCellDef class="basicInfo" [style.text-align]="'center'" [attr.colspan]="10">
          Basic Information
        </th>
      </ng-container>
      <!-- Header row secnd group -->
      <ng-container matColumnDef="header-row-second-group">
        <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" class="supplierDetails" [attr.colspan]="4">
          Second
          group </th>
      </ng-container>

      <tr mat-header-row class="topHeader"
        *matHeaderRowDef="['header-row-first-group','header-row-second-group']; sticky: true"></tr>

      <tr mat-header-row class="nestedHeader" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</section>