<div class="customer-order" (click)="closeOpenPopups()">
  <div class="oms-wrapper">
    <h1 class="heading-one" style="margin: unset;">Customer Order</h1>
  </div>


  <div class="oms-wrapper d-flex customerFilterSec" style="width: 100%;">
    <div class="input-group input-addon" [formGroup]="filtersForm">
      <input type="text" [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}" class="form-control"
        placeholder="Search">
      <div class="input-group-btn ">
        <mat-form-field appearance="fill">
          <mat-label>Select Id</mat-label>
          <mat-select (click)="$event.stopPropagation();" #selectedIdSelect formControlName='selectedId'>
            <mat-option *ngFor="let item of filterOptions" [value]='item'>{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div style="padding-left: 15px;">
      <button mat-flat-button color="primary" class="primary" (click)="filterCall()">Apply</button>
    </div>
    <div style="padding-left: 15px;">
      <button mat-flat-button color="primary" class="secondary" (click)="resetFilters()">Reset Filter</button>
    </div>

    <div style="padding-left: 10px;">
      <button mat-flat-button color="primary" [routerLink]="'bulkorder-upload'">Bulk Order Upload</button>
    </div>

    <a class="alignToRht flex-center" href="javascript:void(0)">
      <img src="../assets/images/download-2-line.png" alt="" (click)=getDownloadData()>
      Download
    </a>

  </div>



  <mat-card style="padding: unset;">
    <div class="chkAndPaginationWrap" *ngIf="order_Data.length && !dataNot_found">
      <!-- <div class="oms-wrapper d-flex my-8 ml-20" style="margin:unset; padding-left: unset"> -->
      <section class="lftCheckBoxField">
        <span class="chkItem" *ngIf="order_Data.length && !dataNot_found">
          <mat-checkbox class="example-margin" [(ngModel)]='basicInfoCheck' disabled>Basic Info.</mat-checkbox>
        </span>
        <span class="chkItem" *ngIf="order_Data.length && !dataNot_found">
          <mat-checkbox class="example-margin" [(ngModel)]='customerInfoCheck'
            (ngModelChange)="displayDyanamicTab('customer')">Customer Info.</mat-checkbox>
        </span>
        <span class="chkItem" *ngIf="order_Data.length && !dataNot_found">
          <mat-checkbox class="example-margin" [(ngModel)]='supplierDetailsCheck'
            (ngModelChange)="displayDyanamicTab('suplier')">Supplier Details</mat-checkbox>
        </span>
      </section>
      <div class="mogb_paginationrow" *ngIf="totalCount > 0">
        <custom-pagination [pageSizeOptions]="pageSizeOptions" [count]="totalCount" [pageSize]="pageSize"
          [(pageNumber)]="pageNumber" (currPageChange)="onPageChange($event)">
        </custom-pagination>
      </div>
    </div>

    <section *ngIf="dataNot_found && !order_Data.length">
      <h4 *ngIf="dataNot_found">!!Data Not Found</h4>
    </section>


    <section class="finance-cpstickytable aa" style="margin: unset;" *ngIf="order_Data.length && !dataNot_found">
      <div class="inner-cpsticky example-container mat-elevation-z8">
        <table class="wid100" mat-table
          [ngClass]="(customerInfoCheck == true  || supplierDetailsCheck == true)? 'tableDynamicClass' : ''"
          [dataSource]="order_Data">

          <ng-container matColumnDef="orderId" sticky>
            <th mat-header-cell *matHeaderCellDef> <span class="twoRow">Order Id/</span> <span class="twoRow">Customer
                Id</span></th>
            <td mat-cell *matCellDef="let element"> <span class="twoRow orderBlue">{{element.orderId}} </span> <span
                class="twoRow">{{element.customerId}}</span></td>
          </ng-container>
          <ng-container matColumnDef="itemId" sticky>
            <th mat-header-cell *matHeaderCellDef>Item Id</th>
            <td mat-cell *matCellDef="let element">
              {{element.itemId}} </td>
          </ng-container>
          <ng-container matColumnDef="quantity" sticky>
            <th mat-header-cell *matHeaderCellDef>Qty.</th>
            <td mat-cell *matCellDef="let element">{{element.quantity}} </td>
          </ng-container>
          <ng-container matColumnDef="trxDate" sticky>
            <th mat-header-cell *matHeaderCellDef>
              <span class="ordersDateRange">
                <span>Txn Date</span>
                <mat-form-field>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate [(ngModel)]="fromDate">
                    <input matEndDate [(ngModel)]="toDate" (dateChange)="filterCustomerOrderlist()">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </span>
            </th>
            <td mat-cell *matCellDef="let element">{{element.orderDate}}</td>
          </ng-container>
          <ng-container matColumnDef="orderValue" sticky>
            <th mat-header-cell *matHeaderCellDef> Unit Price</th>
            <td mat-cell *matCellDef="let element" class="hoverTooltipBlock">
              <!-- {{element.poTotal}} -->
              <!-- <span class="material-icons">info</span> -->
              <div class="hover-tooltip tooptip-style-1">
                {{element.unitPrice}}
                <span class="material-icons">info</span>
                <div class="tooltip-msg-box">
                  <div class="tooltip-msg-left d-flex flex-column w-50 ">
                    <h5 class="text-nowrap">Item </h5>
                    <ul class="text-nowrap">
                      <li>Product Mrp : {{element.productMrp}}</li>
                      <li>Unit Price : {{element.unitPrice}}</li>
                      <li>Tax Rate%: {{element.purchaseTax}}</li>
                      <li>Shipping Charge: {{element.itemShipping}}</li>
                      <li>Coupon Disc: {{element.itemDiscount}}</li>
                      <li *ngIf="element.isEmi == 1">Emi Disc : {{element.emiDiscount}}</li>
                    </ul>
                  </div>
                  <div class="tooltip-msg-right d-flex flex-column w-50 ">
                    <h5 class="text-nowrap">Order</h5>
                    <ul class="text-nowrap">
                      <li>Order Value: {{element.productMrp}}</li>
                      <li>Order Total: {{element.sellingPrice}}</li>
                      <li>Order Discount: {{element.orderDiscount}}</li>
                      <li>Order Shipping: {{element.orderShipping}}</li>
                      <li *ngIf="element.isEmi == 1">Order EmiDisc: {{element.orderEmiDiscount}}</li>

                    </ul>
                  </div>

                </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="productName" sticky>
            <th mat-header-cell *matHeaderCellDef> <span class="twoRow">Product Name/</span> <span
                class="twoRow">MSN</span></th>
            <td mat-cell *matCellDef="let element"> <span class="twoRow orderBlue"><a [href]="element.productImageUrl"
                  target="_blank">{{element.productName}}</a></span> <span class="twoRow"><a [href]="element.productUrl"
                  target="_blank">{{element.productRef}}</a></span> </td>
          </ng-container>
          <ng-container matColumnDef="itemStatus" sticky>
            <th (click)="$event.stopPropagation();" mat-header-cell *matHeaderCellDef [matMenuTriggerFor]="menu1">
              Status<span class="material-icons">
                arrow_drop_down
              </span></th>
            <mat-menu (click)="$event.stopPropagation();" #menu1="matMenu" class="multiple-checkbox-modal">
              <input (click)="$event.stopPropagation();" type="text" class="modal-search" value="Search Status" />
              <!-- <mat-checkbox (click)="$event.stopPropagation();" class="multiple-checkbox-section d-flex flex-column mx-15"></mat-checkbox> -->
              <div (click)="$event.stopPropagation();" class="multiple-checkbox-section d-flex flex-column mx-15">
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 10)"
                  class="example-margin">New</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 11)"
                  class="example-margin">Payment Pending</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 12)"
                  class="example-margin">Payment Failed</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 15)"
                  class="example-margin">Assist Convert</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 17)"
                  class="example-margin">Vetification Pending</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 20)"
                  class="example-margin">Verified</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 25)"
                  class="example-margin">Failed Supplier</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 30)"
                  class="example-margin">Supplier Assigned</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 40)"
                  class="example-margin">Produce Procured</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 50)"
                  class="example-margin">LSP Assign</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 60)"
                  class="example-margin">Mainfest Order</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 70)"
                  class="example-margin">Shipped</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 80)"
                  class="example-margin">Return To Origin</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 90)"
                  class="example-margin">Lost</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 100)"
                  class="example-margin">Delivered</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 105)"
                  class="example-margin">Undelivered</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 110)"
                  class="example-margin">Return Initiated</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 200)"
                  class="example-margin">Admin Cancel</mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 210)"
                  class="example-margin">Customer Cancel</mat-checkbox>
                <!-- <mat-checkbox (click)="$event.stopPropagation();" (change)="addToSelectedArray($event, 300)"
                  class="example-margin">Dummy Order</mat-checkbox> -->
              </div>
              <button (click)="filterCustomerOrderlist()" mat-button mat-dialog-close
                class="ml-5 mat-focus-indicator mat-flat-button mat-button-base mat-primary">
                Search
              </button>
            </mat-menu>
            <td mat-cell *matCellDef="let element"> {{element.itemStatus}} </td>
          </ng-container>
          <ng-container matColumnDef="orderType" sticky>
            <th mat-header-cell *matHeaderCellDef>Order Type</th>
            <td mat-cell *matCellDef="let element"> <span class="twoRow">{{element.classificationType}}</span>
              <span class="twoRow orderBlue">{{element.paymentMode}} </span>
              <span class="twoRow " *ngIf="element.isGstInvoice == 1">Tax </span>
              <span class="twoRow " *ngIf="element.isGstInvoice == 0">Retail </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="warehouse" sticky>
            <th mat-header-cell *matHeaderCellDef class="seperator">Warehouse</th>
            <td mat-cell class="seperator" *matCellDef="let element"> <span
                class="twoRow">{{element.warehouseName}}</span>
              <span class="twoRow orderBlue" *ngIf="element.holdStatus == 1">On Hold</span>
            </td>
          </ng-container>


          <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef class="common" [hidden]="!customerInfoCheck">customerName.
            </th>
            <td mat-cell *matCellDef="let element" class="common" [hidden]="!customerInfoCheck">
              {{element?.CustomerInfo?.customerBillingAddress?.firstname}}
              {{element?.CustomerInfo?.customerBillingAddress?.lastname}}</td>
          </ng-container>

          <ng-container matColumnDef="Trn">
            <th mat-header-cell *matHeaderCellDef class="common" [hidden]="!customerInfoCheck">TRN.</th>
            <td mat-cell *matCellDef="let element" class="common" [hidden]="!customerInfoCheck">
              {{element?.CustomerInfo?.customerBillingAddress?.trnNo}}</td>
          </ng-container>
          <ng-container matColumnDef="Phone">
            <th mat-header-cell *matHeaderCellDef class="common" [hidden]="!customerInfoCheck">PhoneNo.</th>
            <td mat-cell *matCellDef="let element" class="common" [hidden]="!customerInfoCheck">
              {{element?.CustomerInfo?.customerBillingAddress?.phone}}</td>
          </ng-container>
          <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef class="common" [hidden]="!customerInfoCheck">Email</th>
            <td mat-cell *matCellDef="let element" class="common" [hidden]="!customerInfoCheck">
              {{element?.CustomerInfo?.customerBillingAddress?.emailId}}</td>
          </ng-container>

          <ng-container matColumnDef="State">
            <th mat-header-cell *matHeaderCellDef class="common" [hidden]="!customerInfoCheck">State</th>
            <td mat-cell *matCellDef="let element" class="common" [hidden]="!customerInfoCheck">
              {{element?.CustomerInfo?.customerBillingAddress?.state}}</td>
          </ng-container>
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef class="common" [hidden]="!customerInfoCheck">City</th>
            <td mat-cell *matCellDef="let element" class="common" [hidden]="!customerInfoCheck">
              {{element?.CustomerInfo?.customerBillingAddress?.city}}</td>
          </ng-container>


          <ng-container matColumnDef="BusinessName">
            <th mat-header-cell *matHeaderCellDef class="common" [hidden]="!supplierDetailsCheck">
              BusinessName.</th>
            <td mat-cell *matCellDef="let element" class="common" [hidden]="!supplierDetailsCheck">
              {{element?.supplierInfo?.supplierInvoiceAddress?.businessName}}</td>
          </ng-container>

          <ng-container matColumnDef="STrn">
            <th mat-header-cell *matHeaderCellDef class="common" [hidden]="!supplierDetailsCheck">TRN.</th>
            <td mat-cell *matCellDef="let element" class="common" [hidden]="!supplierDetailsCheck">
              {{element?.supplierInfo?.supplierInvoiceAddress?.trnNo}}</td>
          </ng-container>

          <ng-container matColumnDef="SState">
            <th mat-header-cell *matHeaderCellDef class="common" [hidden]="!supplierDetailsCheck">State</th>
            <td mat-cell *matCellDef="let element" class="common" [hidden]="!supplierDetailsCheck">
              {{element?.supplierInfo?.supplierInvoiceAddress?.state}}</td>
          </ng-container>
          <ng-container matColumnDef="Scity">
            <th mat-header-cell *matHeaderCellDef class="common" [hidden]="!supplierDetailsCheck">City</th>
            <td mat-cell *matCellDef="let element" class="common" [hidden]="!supplierDetailsCheck">
              {{element?.supplierInfo?.supplierInvoiceAddress?.city}}</td>
          </ng-container>

          <ng-container matColumnDef="star" stickyEnd>
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element" [matMenuTriggerFor]="invoiceMenu">
              <mat-icon>more_vert</mat-icon>
              <mat-menu class="invoiceMenu" #invoiceMenu="matMenu">
                <button (click)="historyInfoDialog(element.itemId)" mat-menu-item>History</button>
                <!-- <button (click)="customerInfoDialog()" mat-menu-item>Split </button> -->
                <button (click)="updateItemStatus(element)" mat-menu-item>Action</button>
                <!-- <button (click)="updateCustomerAddress(element.orderId)" mat-menu-item>Edit Address</button> -->
                <button mat-menu-item (click)="fetchPdf('customerInvoiceUrl', element?.itemId)">Customer Invoice
                </button>
                <button mat-menu-item (click)="fetchPdf('customerShipmentUrl', element?.itemId)">Customer
                  Shipment</button>
                <button mat-menu-item (click)="fetchPdf('customerCreditNote', element?.itemId)">Customer CN</button>
                <span *ngIf="element.itemStatus === 'DELIVERED' || element.itemStatus==='RETURN_INTIATED' ">
                  <button mat-menu-item (click)="fetchPdf('deliveryPodUrl', element?.itemId)">Download POD</button>
                </span>
              </mat-menu>
            </td>
          </ng-container>
          <!-- Header row first group -->
          <ng-container matColumnDef="header-row-first-group" sticky>
            <!-- <th mat-header-cell *matHeaderCellDef class="basicInfo" [style.text-align]="'center'" [attr.colspan]="10">
              Basic Information
            </th> -->
            <th mat-header-cell *matHeaderCellDef class="basicInfo" [style.text-align]="'center'" [attr.colspan]="9">
              Basic Information
            </th>
        
          </ng-container>
          <!-- Header row secnd group -->
          <ng-container matColumnDef="header-row-second-group" *ngIf='customerInfoCheck'>
            <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" class="customerInfo" [attr.colspan]="6">
              CustomerInfo</th>
          </ng-container>
          <ng-container matColumnDef="header-row-third-group" *ngIf='supplierDetailsCheck'>
            <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" class="supplierDetails"
              [attr.colspan]="5">SupplierInfo</th>
          </ng-container>
          <!-- <tr mat-header-row class="topHeader" *matHeaderRowDef="['header-row-first-group','header-row-second-group']; sticky: true"></tr> -->
          <tr mat-header-row class="topHeader" *matHeaderRowDef="matHeaderDif; sticky: true"></tr>
          <tr mat-header-row class="nestedHeader" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </section>
  </mat-card>
</div>