import {Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges} from '@angular/core';

@Component({
    selector: 'custom-pagination',
    templateUrl: 'customPagination.component.html',
    styleUrls:['customPagination.scss']
})

export class CustomPaginationComponent implements OnInit, OnChanges {
    //constructor(private pagerService: CustomPaginationService) { }
    constructor() { }
    @Input() pageSizeOptions;
    @Input() count: number;
    @Input() pageSize: number = 10;
    @Input() pageNumber: number = 1;
    selectedOption = '10';
    @Output() currPageChange: EventEmitter<any> = new EventEmitter();

    pages: number[] = [];
    totalPages = 0;
    pageDisplay = 1;
    maxPagesToDisplay = 10;

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        //console.log('page changes', changes);
        this.getTotalPages();
    }

    updatePageSize(e) {
        this.pageNumber = 1;
        this.currPageChange.emit({pageSize: this.selectedOption, pageNumber: this.pageNumber});
    }

    //display
    displayPageControls() { return this.pages.length > 1; }
    getPageStart() { return (this.pageNumber * this.pageSize) - this.pageSize + 1; }
    getPageEnd() { return (this.pageNumber * this.pageSize) > this.count ? this.count : (this.pageNumber * this.pageSize) }
    getTotalPages() {
        this.totalPages = Math.ceil(this.count / this.pageSize);
        this.pageNumber = Number(this.pageNumber);
        if (this.pageNumber > 10) {
            if (this.pageNumber === this.totalPages) {
                this.pageDisplay = this.totalPages - 9;
                this.maxPagesToDisplay = this.totalPages;
            } else {
                this.pageDisplay = this.pageNumber - 8;
                this.maxPagesToDisplay = this.pageNumber + 1;
            }
        } else if (this.pageNumber === 10){
            this.pageDisplay = 2;
            this.maxPagesToDisplay = 11;
        } else {
            this.pageDisplay = 1;
            this.maxPagesToDisplay = 10;
        }
        this.getPagesArray();
    }

    getPagesArray()
    {
        this.pages = [];
        let arr: number[] = [];
        for (let index = 1; index <= this.totalPages; index++) {
            arr.push(index);
        }
        this.pages =  arr;
    }
    /** @10766:Pagination logic using the page number
        * @params:pageIndex:page number
    */
    setPageByValue(pageIndex)
    {
        this.pageNumber = pageIndex;
        if (pageIndex === this.maxPagesToDisplay) {
            this.maxPagesToDisplay = this.maxPagesToDisplay + 1;
            this.pageDisplay = this.pageDisplay + 1;
        }
        if (pageIndex === this.pageDisplay && pageIndex > 1) {
            this.pageDisplay = this.pageDisplay - 1;
            this.maxPagesToDisplay = this.maxPagesToDisplay - 1;
        }
        this.currPageChange.emit({pageSize: this.selectedOption, pageNumber: this.pageNumber});
    }

        /** @10766:Pagination logic using the NEXT, PREV button
        * @params:control:type of NETX/PREV.
    */
    setPageByControl(control)
    {
        window.scrollTo(0, 0);
        if (control.toUpperCase() === 'NEXT') {
            if (this.pageNumber < this.pages.length) {
                this.pageNumber = this.pageNumber + 1;
                if (this.pageNumber < this.pages.length && this.pageNumber === this.maxPagesToDisplay) {
                this.maxPagesToDisplay = this.maxPagesToDisplay + 1;
                this.pageDisplay = this.pageDisplay + 1;
                }
            }
        } else if (control.toUpperCase() === 'PREV') {
            if (this.pageNumber >= 2) {
                this.pageNumber = this.pageNumber - 1;
                if (this.pageNumber > 1 && this.pageNumber === this.pageDisplay) {
                this.pageDisplay = this.pageDisplay - 1;
                this.maxPagesToDisplay = this.maxPagesToDisplay - 1;
                }
            }
        }
        else if (control.toUpperCase() === 'FIRST') {
            if (this.pageNumber >= 2) {
                this.pageNumber = 1;
                if (this.totalPages < 10) {
                    this.maxPagesToDisplay = this.totalPages;
                } else {
                    this.maxPagesToDisplay = 10;
                }
                this.pageDisplay = 1;
            }
        }
        else if (control.toUpperCase() === 'LAST') {
            if (this.pageNumber < this.pages.length) {
                this.pageNumber = this.pages.length;
                if (this.pageNumber > this.maxPagesToDisplay) {
                    this.maxPagesToDisplay = this.pageNumber;
                    //this.pageDisplay = (this.pageNumber % 10) ? this.maxPagesToDisplay - ((this.pageNumber % 10) - 1) : this.maxPagesToDisplay - 9;
                    this.pageDisplay = (this.totalPages >= 10) ? (this.pageNumber - 9) : 1;
                }
            }
        }
        this.currPageChange.emit({pageSize: this.selectedOption, pageNumber: this.pageNumber});
    }


}
