<mat-dialog-actions align="end">
  <button style="pointer-events: auto; cursor: pointer;" mat-button mat-dialog-close class="close-btn"><span class="material-icons"> close</span></button>
</mat-dialog-actions>
<div class="wrapper"  style="pointer-events: auto; cursor: pointer;">
  <div>
    <mat-dialog-content class="mat-typography modal-raduis">
      <div class="flex-center justify-between">
        <h1 class="modal-heading m-0">Action </h1>
      </div>
      <h3 class="modal-sub-heading"> Po ID: <span class="ml-5"> {{itemAction?.poId}}</span> </h3>
      <h3 class="modal-sub-heading"> Current State: <span class="ml-5">{{itemAction?.poStatus}} </span> </h3>
      <h3 class="modal-sub-heading"> Product Name: <span class="ml-5"> {{itemAction?.productName}}</span> </h3>
      <h3 class="modal-sub-heading">Current Supplier: <span class="ml-5">{{itemAction?.businessName}}</span></h3>
    </mat-dialog-content>
    <div>
      <h4>Next allowed states:</h4>
      <select #selectedData [(ngModel)]="selectedNextStatus" (ngModelChange)="enableOrNot()">
        <option value="0" selected="true" disabled="disabled">-- SELECT --</option>
        <option *ngFor="let menulist of options" [ngValue]="menulist">{{menulist.name}}</option>
      </select>
    </div>
  </div>
  <div>
    <div class="productdetailrow bgwhite clearfix" *ngIf="selectedNextStatus">
      <form [formGroup]="statusFOrm">
        <div class="mou-formarea clearfix">
          <div class="col-md-12 pl-0">
            <div class="infofield_row clearfix" style="display: flex;padding-top: 30px;">
              <div class="halffield" *ngIf="showPickupDate">
                <div class="inputfield date">
                  <label>Pickup Date<em>*</em></label>
                  <input type="date" formControlName="pickupDate" placeholder="dd/mm/yyyy">
                  <h6 class="text-danger" *ngIf="statusFOrm.get('pickupDate').hasError('required') && 
                    statusFOrm.get('pickupDate').touched">
                    Please select the Pickup Date
                  </h6>
                </div>
              </div>
              <div class="halffield" *ngIf="showInstructions">
                <div class="inputfield">
                  <label>Instructions</label>
                  <input type="text" formControlName="instructions" placeholder="Please Enter Instructions" />
                </div>
              </div>
              <div class="halffield" *ngIf="showRemarks">
                <div class="inputfield">
                  <label>Remarks</label>
                  <input type="text" formControlName="remarks" placeholder="Please Enter Remarks" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="rightactions" style="text-align: inherit;" *ngIf="showRemarks || showInstructions || showPickupDate">
        <button class="v-btn submit" (click)="upDateStatus()">Submit</button>
      </div>
    </div>
  </div>
</div>