<mat-dialog-content class="mat-typography position-left">
  <div class="d-flex">
    <mat-dialog-actions align="start">
      <button mat-button mat-dialog-close class="close-btn-black"> <span class="material-icons">
          close
        </span></button>
    </mat-dialog-actions>
    <div class="searchbar ml-20">
      <input type="text" class="form-control" placeholder="Search Task">
      <div class="input-group-icon ">
        <span class="material-icons">
          search
        </span>
      </div>
    </div>
    <!-- <button mat-button class="ml-auto icon-btn"><span class="material-icons mr-5 ">
        settings
      </span>Settings</button> -->
  </div>

  <mat-tab-group class="tabGroupMenu" [@.disabled]="true">
    <div *ngFor="let meuItem of menuList ">

      <mat-tab [label]="meuItem.featureName">
        <ng-template mat-tab-label>

          <div class="menu-tab itemRow" [matMenuTriggerFor]="supplierDamage"
            *ngIf="meuItem.featureName != 'Finance Report' && meuItem.featureName != 'Monthly Reporting' && meuItem.featureName != 'Payout' && meuItem.featureName != 'Purchase Order' && meuItem.featureName != 'Customer order' &&  meuItem.featureName != 'Add Menu' &&  meuItem.featureName != 'Edit User Access' &&  meuItem.featureName != 'Tech Support'">
            <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-1" (click)="navigateTo(meuItem)">
              <img src="../assets/images/Add Menu.svg" alt="">{{meuItem.featureName}}</span>
            <span class="material-icons">
              arrow_forward
            </span>
          </div>

          <mat-menu #supplierDamage="matMenu" backdropClass="secondLevel">
            <span mat-menu-item mat-dialog-close (click)="navigateTo({url:'/damage-business'})">Business </span>
            <span mat-menu-item mat-dialog-close (click)="navigateTo({url:'/damage-logistic'})">Logistics</span>
            <span mat-menu-item mat-dialog-close (click)="navigateTo({url:'/damage-finance'})">Finance</span>
          </mat-menu>

          <span class="itemRow" *ngIf="meuItem.featureName == 'Tech Support'" [matMenuTriggerFor]="reporting">
            <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-1" mat-dialog-close>
              <span (click)="navigateTo(meuItem)">
                <img src="../assets/images/marketing.svg" alt="">
                {{meuItem.featureName}}
              </span>
              <span class="material-icons">
                arrow_forward
              </span>
            </span>
          </span>

          <!-- <div class="menu-tab itemRow" *ngIf="meuItem.featureName == 'Tech Support'">
            <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-1" mat-dialog-close
              (click)="navigateTo(meuItem)">
              <span>
                <img src="../assets/images/Add Menu.svg" alt="">
                {{meuItem.featureName}}
              </span>
              <span class="material-icons">
                arrow_forward
              </span>
            </span>
          </div> -->


          <span class="itemRow" *ngIf="meuItem.featureName == 'Finance Report'" [matMenuTriggerFor]="reporting">
            <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-1">
              <span>
                <img src="../assets/images/Finance.svg" alt="">
                {{meuItem.featureName}}
              </span>
              <span class="material-icons">
                arrow_forward
              </span>
            </span>
          </span>


          <mat-menu #reporting="matMenu" backdropClass="secondLevel">
            <span mat-menu-item (click)="navigateTo({url:'/finance'})" mat-dialog-close>finance </span>
            <span mat-menu-item (click)="navigateTo({url:'/monthly-reporting'})"
              mat-dialog-close>monthly-reporting</span>
          </mat-menu>


          <div class="menu-tab itemRow" *ngIf="meuItem.featureName == 'Add Menu'">
            <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-1" mat-dialog-close
              (click)="navigateTo(meuItem)">
              <span>
                <img src="../assets/images/Add Menu.svg" alt="">
                {{meuItem.featureName}}
              </span>
              <span class="material-icons">
                arrow_forward
              </span>
            </span>
          </div>

          <div class="menu-tab itemRow" *ngIf="meuItem.featureName == 'Edit User Access'">
            <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-1" mat-dialog-close
              (click)="navigateTo(meuItem)">
              <span>
                <img src="../assets/images/Edit User.svg" alt="">
                {{meuItem.featureName}}
              </span>
              <span class="material-icons">
                arrow_forward
              </span>
            </span>
          </div>

          <div class="menu-tab itemRow" *ngIf="meuItem.featureName == 'Payout'">
            <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-1" mat-dialog-close
              (click)="navigateTo(meuItem)">
              <span>
                <img src="../assets/images/Payout.svg" alt="">
                {{meuItem.featureName}} Menu
              </span>
              <span class="material-icons">
                arrow_forward
              </span>
            </span>
          </div>

          <div class="menu-tab itemRow" *ngIf="meuItem.featureName == 'Purchase Order'">
            <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-1" mat-dialog-close
              (click)="navigateTo(meuItem)">
              <span>
                <img src="../assets/images/Purchase Order.svg" alt="">
                {{meuItem.featureName}}
              </span>
              <span class="material-icons">
                arrow_forward
              </span>
            </span>
          </div>

          <div class="menu-tab itemRow" *ngIf="meuItem.featureName == 'Customer order'">
            <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-1" mat-dialog-close
              (click)="navigateTo(meuItem)">
              <span>
                <img src="../assets/images/Customer Order.svg" alt="">
                {{meuItem.featureName}}
              </span>
              <span class="material-icons">
                arrow_forward
              </span>
            </span>
          </div>


          <!-- <div class="finance-tab">
            <span class="menu-tab-2" *ngIf="meuItem.featureName == 'Finance Report'" [matMenuTriggerFor]="reporting">
              <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-1" mat-dialog-close>
                <img src="../assets/images/Group11923.svg" alt="">{{meuItem.featureName}}</span>
              <span class="material-icons">
                arrow_forward
              </span> </span>
  
            <mat-menu class="sub-menu-mat" #reporting="matMenu">
              <span mat-menu-item  (click)="navigateTo({url:'/finance'})">finance </span>
              <span mat-menu-item (click)="navigateTo({url:'/monthly-reporting'})" >monthly-reporting</span>
            </mat-menu>
          </div> -->
        </ng-template>
        <!-- <div class="mega-menu-items mordern-scrollbar">
            <button mat-button [routerLink]="['/customer-order']">Customer Order</button>
            <button mat-button>Invoice</button>
            <button mat-button>Packed</button>
            <button mat-button>Manifest</button>
            <button mat-button>Pending Pickup</button>
            <button mat-button>Citi Upload</button>
            <button mat-button>Liquidate</button>
            <button mat-button>LSP Debit</button>
            <button mat-button>Item History</button>
            <button mat-button>Bulk State Movement</button>
            <button mat-button>Pincode</button>
            <button mat-button>Invoice Map</button>
            <button mat-button>Supplier Invoice</button>
            <button mat-button>Product Serial</button>
            <button mat-button>Supplier Payout</button>
            <button mat-button>Payout</button>
            <button mat-button>Month-wise Invoice</button>
            <button mat-button>Month-wise Credit Note</button>
            <button mat-button>Invoice Map item</button>
            <button mat-button>Bulk Order Push</button>
            <button mat-button>Customer Refunds</button>
          </div> -->
      </mat-tab>
    </div>

    <!-- <mat-tab label="Customer Support"> 
      <ng-template mat-tab-label>
        <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-2"><img src="../assets/images/customer-suppport.svg" alt=""> Customer Support</span>
        <span class="material-icons">
          arrow_forward
          </span>
      </ng-template>
      <div class="mega-menu-items mordern-scrollbar">
        <button mat-button>Basic 2</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
      </div>
    </mat-tab>
    <mat-tab label="Warehouse"> 
      <ng-template mat-tab-label>
        <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-3"><img src="../assets/images/werehouse.svg" alt=""> Warehouse</span>
        <span class="material-icons">
          arrow_forward
          </span>
       </ng-template>
       <div class="mega-menu-items mordern-scrollbar">
        <button mat-button>Basic 3</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
      </div>
    </mat-tab>
    <mat-tab label="Logistics"> 
      <ng-template mat-tab-label>
        <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-4"><img src="../assets/images/logistics.svg" alt="">Logistics</span>
        <span class="material-icons">
          arrow_forward
          </span>
      </ng-template>
      <div class="mega-menu-items mordern-scrollbar">
        <button mat-button>Basic 4</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
      </div>
    </mat-tab>
    <mat-tab label="Finance"> 
      <ng-template mat-tab-label>
        <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-5"><img src="../assets/images/finance.svg" alt=""> Finance</span>
        <span class="material-icons">
          arrow_forward
          </span>
      </ng-template>
      <div class="mega-menu-items mordern-scrollbar">
        <button mat-button>Basic 5</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
      </div>
    </mat-tab>
    <mat-tab label="Central Ops"> 
      <ng-template mat-tab-label>
        <span class="megaMenuarrow megamenu-icons megamenu-icons-bg-6"><img src="../assets/images/centralOps.svg" alt="">Central Ops</span>
        <span class="material-icons">
          arrow_forward
          </span>
      </ng-template>
      <div class="mega-menu-items mordern-scrollbar">
        <button mat-button>Basic 6</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
        <button mat-button>Basic</button>
      </div>
    </mat-tab> -->
  </mat-tab-group>

</mat-dialog-content>