import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { CommonService } from '../_services/common.service';

export interface PeriodicElement {
  email: string;
  itemStatus: string;
  supplierName: string;
  remark: string;
  valueUpdate : string;
  assignedTo : string;
  timeStamp : string;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   {email: 'natesh.kumar@moglix.com', itemStatus: 'PAYMENT_PENDING', supplierName: 'ECS corporation', remark: 'Order pushed from new order queue', valueUpdate: 'Automated Transition',assignedTo: 'Assignee',timeStamp : '2021-02-09 04:06:00'},
//   {email: 'natesh.kumar@moglix.com', itemStatus: 'PAYMENT_PENDING', supplierName: 'ECS corporation', remark: 'Order pushed from new order queue', valueUpdate: 'Automated Transition',assignedTo: 'Assignee',timeStamp : '2021-02-09 04:06:00'}
  
// ];


// const demo = {
//   displayedColumns: ['email', 'itemStatus', 'supplierName', 'remark', 'valueUpdate','assignedTo','timeStamp'],
//   dataSource: ELEMENT_DATA,
// }


@Component({
  selector: 'app-history-popup',
  templateUrl: './history-popup.component.html',
  styleUrls: ['./history-popup.component.scss']
})

export class HistoryPopupComponent implements OnInit, OnDestroy {

  // customer item history
  orderItemHistoryId:any;
  historyData:any=[];

  // purchase item history
  historyItemId : any;
  purchasrOrderHistory : any = [];

  displayPurchase: ['poId', 'itemId', 'poUniqueId', 'userId', 'name', 'email', 'itemStatus', 'supplierName', 'timeStamp','remark'];
  nodata : any =  false;
  constructor(private authService : AuthService, private _commonService: CommonService) { }

  ngOnInit(): void {

    
    this.authService.historyItemId.subscribe((itemId)=>{
     // 931
      this.historyItemId =  itemId;
      if(this.historyItemId != ''){
        this.getpurchaseorderHistory();
      }
     // this.subscriptions.push(subscription);
    })
    

    
    this.authService.orderItemHistoryId.subscribe((itemId)=>{
      this.orderItemHistoryId =  itemId;
      if(this.orderItemHistoryId != ''){
        this.authService.getCustomerOrderHistory(this.orderItemHistoryId).subscribe((response) => {
          if(response.code==200){
            console.log(response);
            this.historyData=response.data

          }
          console.log(this.historyData);
        }, (err)=>{
          this.historyData = [];
          this.nodata = true;
          console.log(err.message);
        })
      }
    })
  }

  getpurchaseorderHistory(){
    this._commonService.showLoader();
    if( this.historyItemId != '' &&  this.historyItemId != null && this.historyItemId != undefined){
      this.authService.getOrdersHistory(this.historyItemId).subscribe((data)=>{
        if(data.code==200 && data.success){
          this.purchasrOrderHistory = data?.data[0];
        }else{
          this.purchasrOrderHistory = [];
          this.nodata = true;
        }
        console.log("===this.purchasrOrderHistory", this.purchasrOrderHistory);
        this._commonService.hideLoader();
      }, (err)=>{
        this.purchasrOrderHistory = [];
        this.nodata = true;
        console.log(err.message);
        this._commonService.hideLoader();
      })
    }
  }

  ngOnDestroy() {

    // this.subscriptions.forEach(x => {
    //   if(!x.closed) {
    //     x.unsubscribe();
    //   }
    // });
    //localStorage.setItem('itemId', '');
    this.authService.historyItemId.next('');
    this.authService.orderItemHistoryId.next('');

  }
  
}
