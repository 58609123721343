import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { ItemActionPopupComponent } from '../item-action-popup/item-action-popup.component';
import { CustomerinfoDialogComponent } from '../customerinfo-dialog/customerinfo-dialog.component';
import { HistoryPopupComponent } from '../history-popup/history-popup.component';

import { AuthService } from '../_services/auth.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { CommonService } from '../_services/common.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
  selector: 'app-customer-order',
  templateUrl: './customer-order.component.html',
  styleUrls: ['./customer-order.component.scss']
})

export class CustomerOrderComponent implements OnInit {
  pageSizeOptions = ['10', '25', '50', '100'];
  is_customerInfo = false;
  basicInfoCheck : boolean = true;
  customerInfoCheck: boolean = false;
  supplierDetailsCheck: boolean= false;
  basicCol:any = 9;
  trx_date = '';
  displayedColumns =['orderId','itemId','quantity','trxDate','orderValue','productName', 'itemStatus', 'orderType', 'warehouse','firstname','Trn','Phone','Email','State','city', 'BusinessName','STrn', 'SState','Scity','star'];
  order_Data: any = [];
  matHeaderDif : any = ['header-row-first-group'];
  headerCheck : boolean = false;
  customerInfoList : any = [];
  supplierInfoList : any = [];
  totalCount : any = 0;
  pageSize;
  pageNumber = 1;
  milliSecs: number = 10000;
  filtersForm = new FormGroup({
    selectedId : new FormControl("")

    });
  phone:any = '';
  email:any = '';
  dataNot_found : any = false;
  // ,'MSN','CustomerId', 'CustomerEmail','CustomerPhone','WarehouseId'
  filterOptions: any = ['orderId','itemId'];
  searchValue : any;

  actionPart=['History','Action','Add Comment','Download Invoice','Packaging','Assign To'];
  
  is_supplietInfo = false;
  
  @ViewChild(MatMenuTrigger) invoiceMenu: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) menu1: MatMenuTrigger;
  @ViewChild('selectedIdSelect') selectedIdSelect;
  closeOpenPopups(){
    this.selectedIdSelect.close();
    this.menu1.closeMenu();
    this.invoiceMenu.closeMenu();
  }

  constructor(private _commonService: CommonService, public dialog: MatDialog, private authService : AuthService, private cdr: ChangeDetectorRef, private token : TokenStorageService) {
    this.pageSize = this.pageSizeOptions[0];
  }

  statusArray = [];
  addToSelectedArray(e, val) {
    if (e.checked) {
      this.statusArray.push('' + val);
    }else {
      this.statusArray = this.statusArray.filter(el => el.toString() != val.toString());
    }
  }

  filterCustomerOrderlist() {
    const data = this.applyFilters();
    data.itemStatus = this.statusArray;
    this.getCustomer_orderlist(data);
  }

  handleUpdatedPageSize(obj) {
    this.pageSize = obj.pageSize || this.pageSizeOptions[0];
    this.pageNumber = obj.pageNumber;
    this.onPageChange({pageSize: this.pageSize, pageNumber: this.pageNumber});
  }

  ngAfterViewChecked(){
      this.cdr.detectChanges();
  }

  updateItemStatus(item){
    this.authService.actionItem.next(item);
    const dialogRef = this.dialog.open(ItemActionPopupComponent,{
      width: '100%',
      backdropClass: 'HistoryBackdropClass',
      panelClass: 'custom-popup-1'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.callOrderData();
    });
  }

  historyInfoDialog(item) {
    this.authService.orderItemHistoryId.next(item);
    
    const dialogRef = this.dialog.open(HistoryPopupComponent,{
      width: '100%',
      backdropClass: 'HistoryBackdropClass',
      panelClass: 'custom-popup-1'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

    });
  }

  customerInfoDialog() {
    const dialogRef = this.dialog.open(CustomerinfoDialogComponent,{
      width: '100%',
      backdropClass: 'customerInfoBackdropClass',
      panelClass: 'custom-popup-1'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit() {
    // get basic order list intial data
    console.log("inside init");
    // alert("tokennnnnn"+this.token.getToken())
    this.getCustomer_orderlist(this.applyFilters());
  }

  pdfData = {};
  fetchPdf(name, itemId) {
    let req = {
      itemId
    }
    if (!this.pdfData || !this.pdfData[itemId]) {
      this.authService.fetchCustomerInvoicePdf(req).subscribe(res => {
        console.log(res);
        if(res.success) {
          this.pdfData[itemId] = res.data[0][0];
          if (this.pdfData[itemId][name]) {
            window.open(this.pdfData[itemId][name]);
          } else {
            alert('Data not found');
          }
        } else {
          alert(res.message);
        }
      })
    } else {
      if (this.pdfData[itemId] && this.pdfData[itemId][name]) {
        window.open(this.pdfData[itemId][name]);
      } else {
        alert('Data not found');
      }
    }
  }

  getCustomer_orderlist(data){
    // alert('getCustomer_orderlist');
    this._commonService.showLoader();
    data['countryCode'] = localStorage.getItem('CountryCode') == 'me' ?  'UAE' : 'IN';
    console.log("search data "+data?.['selectedId']);

    if(data?.['selectedId']){
    console.log(this.searchValue);
      if(this.searchValue == '' || this.searchValue == undefined){
        alert('Search value is required');
        this._commonService.hideLoader();
        return;
      }

      data[data.selectedId] = this.searchValue.split(','); 
      //delete data['selectedId'];
    }
    data['phone'] = this.phone;
    data['email'] = this.email;

    if (this.toDate && this.fromDate) {
      let fullToDate;
      let fullFromDate;
      let toDate;
      toDate = new Date(this.toDate);
      let month = toDate.getMonth()+1;
      fullToDate = toDate.getFullYear() + '-' + (month < 10 ?  '0' + month : month) + '-' + (toDate.getDate() < 10 ? '0'+toDate.getDate() : toDate.getDate());


      let fromDate;
      fromDate = new Date(this.fromDate);
      month = fromDate.getMonth()+1;
      fullFromDate = fromDate.getFullYear() + '-' + (month < 10 ?  '0' + month : month) + '-' + (fromDate.getDate() < 10 ? '0'+fromDate.getDate() : fromDate.getDate());

      data['dateTo'] = fullToDate;
      data['dateFrom'] = fullFromDate;
    }

    this.authService.customerOrderList(data, this.token.getToken()).subscribe((data)=>{
      if(data?.success && data?.code == 200 && data?.data?.length){
          console.log(data);
          console.log("count "+ data?.data[0].totalCount);

          this.order_Data = data?.data[0].listData;
          this.totalCount =  data?.data[0].totalCount;
          this.dataNot_found = false;
          this.order_Data.forEach(element => {
            element['checkbox'] = false; 
          });
          console.log(this.totalCount);
      }else{
        alert(data?.message);
        this.dataNot_found = true;
        this.order_Data = [];
        this.totalCount = 0;
      }
      this._commonService.hideLoader();
    }, err => {
      alert(err.error.error);
      this._commonService.hideLoader();
    })

  }


  onPageChange(event)
  {
      const {pageSize, pageNumber} = event;
      this.pageNumber = parseInt(pageNumber);
      this.pageSize = parseInt(pageSize);
      this.getCustomer_orderlist(this.applyFilters());
      this.matHeaderDif = ['header-row-first-group'];
      this.customerInfoCheck = false;
      this.supplierDetailsCheck = false;
      this.basicCol = 9;
  }

  displayDyanamicTab(tabName){
    if(tabName == 'customer' && this.customerInfoCheck){
      this.matHeaderDif.push('header-row-second-group');
      this.get_customer_info();
    }
    if(tabName == 'suplier' && this.supplierDetailsCheck){
      this.matHeaderDif.push('header-row-third-group');
      this.get_supplier_info();
    }
    if(!this.customerInfoCheck){
      this.matHeaderDif = this.matHeaderDif.filter(item => item !== 'header-row-second-group')
    } 
    if(!this.supplierDetailsCheck){
      this.matHeaderDif = this.matHeaderDif.filter(item => item !== 'header-row-third-group');
    }
    
    if(!this.customerInfoCheck && !this.supplierDetailsCheck){
      this.getCustomer_orderlist(this.applyFilters());
    }
  }

  get_customer_info(){
    this._commonService.showLoader();
    let OrderId = [];
    this.order_Data.forEach(element => {
     OrderId.unshift(element.orderId);
    });

    let req = {
      'orderID' : OrderId
    }
    this.authService.getcustomerInfo(req).subscribe((response)=>{
        if(response.code = 200 && response.success){
          this.customerInfoList = response.data[0];
          const keys = Object.keys(this.customerInfoList);
          this.order_Data.forEach((element1) => {
            keys.forEach((element2) => {
                  if(element1.orderId == element2){
                    element1['CustomerInfo'] = this.customerInfoList[element2];
                  }
             })
          });
          console.log("=====this.order_Data", this.order_Data);
        }
        else{
          alert(response.message);
        }
        this._commonService.hideLoader();
    }, (err)=>{
      this._commonService.hideLoader();
      console.log(err.message)
    })
  
  }

  get_supplier_info(){
    this._commonService.showLoader();

    let itemId = [];
    this.order_Data.forEach(element => {
      itemId.unshift(element.itemId);
    });

    let req = {
      'itemID' : itemId
    }
    this.authService.getSupplierInfo(req).subscribe((response)=>{
      console.log("======data", response);
        if(response.code = 200 && response.success){
          this.supplierInfoList = response?.data[0];
          const keys = Object.keys(this.supplierInfoList);
          this.order_Data.forEach((element1) => {
            keys.forEach((element2) => {
                  if(element1.itemId == element2){
                    element1['supplierInfo'] = this.supplierInfoList[element2];
                  }
             })
          });
          console.log("=====this.order_Data supplier", this.order_Data);
        }
        else{
          alert(response.message);
        }
        this._commonService.hideLoader();
    }, (err)=>{
      this._commonService.hideLoader();
      console.log(err.message)
    })
  }

  applyFilters(){
    let data = this.filtersForm.value;
    Object.keys(data).forEach(element => {  if (!data[element]) { delete data[element]; } });
    data = Object.assign(data, { pageNumber: this.pageNumber ? this.pageNumber : 0, pageCount: this.pageSize })
    
    return data;
  }

  toDate;
  fromDate;
  resetFilters(){
    this.toDate = '';
    this.fromDate = '';
    this.filtersForm.get('selectedId').setValue('');
    this.searchValue = '';
    this.pageNumber = 1;
    this.callOrderData();
  }

  filterCall(){
    this.pageNumber =1;
    this.getCustomer_orderlist(this.applyFilters());
    this.matHeaderDif = ['header-row-first-group'];
    this.customerInfoCheck = false;
    this.supplierDetailsCheck = false;
  }

  callOrderData(){
    this.getCustomer_orderlist(this.applyFilters());
    this.matHeaderDif = ['header-row-first-group'];
    this.customerInfoCheck = false;
    this.supplierDetailsCheck = false;
  }

  getDownloadData(){
    this.downloadOrdersList(this.applyFilters())
  }

  downloadOrdersList(reqData){
   // let reqbody ={};
   this._commonService.showLoader();
    reqData.countryCode = localStorage.getItem('CountryCode') == 'me' ?  'UAE' : 'IND';
    reqData.isDownload = true;
    console.log("====reqData", reqData);
    delete reqData.pageNumber;
    delete reqData.pageCount;
    
    if (this.toDate && this.fromDate) {
      let fullToDate;
      let fullFromDate;
      let toDate;
      toDate = new Date(this.toDate);
      let month = toDate.getMonth()+1;
      fullToDate = toDate.getFullYear() + '-' + (month < 10 ?  '0' + month : month) + '-' + (toDate.getDate() < 10 ? '0'+toDate.getDate() : toDate.getDate());


      let fromDate;
      fromDate = new Date(this.fromDate);
      month = fromDate.getMonth()+1;
      fullFromDate = fromDate.getFullYear() + '-' + (month < 10 ?  '0' + month : month) + '-' + (fromDate.getDate() < 10 ? '0'+fromDate.getDate() : fromDate.getDate());

      reqData['dateTo'] = fullToDate;
      reqData['dateFrom'] = fullFromDate;
    }

    if(this.statusArray.length>0){
      reqData.itemStatus = this.statusArray;
    }


    this.authService.customerOrderList(reqData, this.token.getToken()).subscribe((response)=>{
      if (response.success && response.code == 200) {
        this.csv_Template(response?.data[0].listData);
      } else {
        alert("Data Not Found, Try Again");
      }
      this._commonService.hideLoader();
    }, (err)=>{
      this._commonService.hideLoader();
      console.log(err.message);
    })
  }

  csv_Template(data){
    let csvData = data;
    let options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Order-List',
      useBom: true,
      headers:  Object.keys(csvData[0])
    };
    new ngxCsv(csvData, `OrderList${csvData[0].orderId}`, options);
  }

  
}