import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';
import {Router} from '@angular/router';
import { Md5 } from "ts-md5/dist/md5";
import { MicrosoftLoginProvider, SocialAuthService } from 'angularx-social-login';
import { CommonService } from '../_services/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: any = {
    email: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  role = '';
  accessToken: string;
  showLoginCard: boolean = true;

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router,private socialAuthService: SocialAuthService, private commonService: CommonService) { }

  ngOnInit(): void {
    if(this.tokenStorage.getUser()){
       this.router.navigateByUrl('/profile');
    }
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.role = this.tokenStorage.getUser().role;
      this.tokenStorage.saveToken(this.tokenStorage.getToken());
    }
  }

  setValidity(){
    this.isLoginFailed = false;
  }



  microsoftLogin() {
    this.commonService.showLoader();
    console.log("==icrosoftLoginProvider.PROVIDER_ID", MicrosoftLoginProvider.PROVIDER_ID);
    this.socialAuthService.signIn(MicrosoftLoginProvider.PROVIDER_ID);
     this.socialAuthService.authState.subscribe((user) => {
      this.accessToken = user.authToken;
      console.log("== this.accessToken ",  this.accessToken );
      this.microsoftTokenValidation();
    });
  }


  microsoftTokenValidation() {
    this.commonService.showLoader();
    this.showLoginCard = false;
    let reqData = {
      'token': this.accessToken
    }
   // this.loading = true;
    this.authService.microsftLogin(reqData).subscribe(
      data => {
        if (data.code == 200 && data.success == true) {
          // this.tokenStorage.saveToken(data.accessToken);
          // this.tokenStorage.saveUser(data);
          // this.role = this.tokenStorage.getUser().role;
          // this.router.navigateByUrl('/profile');
          this.tokenStorage.saveToken(data.accessToken);
          this.tokenStorage.saveUser(data);
          this.role = this.tokenStorage.getUser().role;
          if(this.role == null){
            this.isLoginFailed = true;
            this.logout();
            this.errorMessage = "Access not granted, contact your manager"
            setTimeout(()=>{
              this.isLoginFailed = false;
            },5000)
            return;
          }else{
            this.authService.getLoggedInOrNot.next(data);
            this.isLoginFailed = false;
            this.isLoggedIn = true;
           this.router.navigateByUrl('/profile');
          }
        }
        else{
          this.errorMessage = data.message;
          this.isLoginFailed = true;
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.commonService.hideLoader();
  }

  onSubmit(): void {
  this.setValidity()
    const { email, password } = this.form;
    console.log(email);
    this.authService.login(email, Md5.hashStr(password)).subscribe(

      data => {
        console.log(data);
        if(data.success){
          this.tokenStorage.saveToken(data.accessToken);
          this.tokenStorage.saveUser(data);
          this.role = this.tokenStorage.getUser().role;
          if(this.role == null){
            this.isLoginFailed = true;
            this.logout();
            this.errorMessage = "Access not granted, contact your manager"
            setTimeout(()=>{
              this.isLoginFailed = false;
            },5000)
            return;
          }else{
            this.authService.getLoggedInOrNot.next(data);
            this.isLoginFailed = false;
            this.isLoggedIn = true;
           this.router.navigateByUrl('/profile');
          }
        }
        else{
          this.errorMessage = data.message;
          this.isLoginFailed = true;
        }
        
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }


  logout() : void{
    console.log("inside logout");
    let currentUser = window.localStorage.getItem('auth-user');
    window.localStorage.removeItem('auth-token');
    window.localStorage.removeItem('auth-user');
    let user  = JSON.parse(currentUser);
    this.authService.logout(user.email, user.accessToken).subscribe(
      (data) => {
        console.log("logout success"+data);
        if(data.success){
          this.isLoggedIn = false;
          this.tokenStorage.logoutUser();
        }
        else{
          console.log("test logout fail");
          this.isLoggedIn = false;
          this.tokenStorage.logoutUser();
          
          this.router.navigateByUrl('/');
        }       
        
      },(err) => {
        console.log(err.message)
      }
    );
  }
}