import { Component, OnInit } from '@angular/core';

import { AuthService } from '../_services/auth.service';
import { FormControl, FormGroup} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../_services/common.service';

@Component({
  selector: 'app-item-action-popup',
  templateUrl: './item-action-popup.component.html',
  styleUrls: ['./item-action-popup.component.scss']
})
export class ItemActionPopupComponent implements OnInit {

  constructor(private _commonService: CommonService, private authService : AuthService,  public dialogRef: MatDialogRef<ItemActionPopupComponent>) { }
  
  newStateTransitionMap=[{
    NEW: [
    			{name:'VERIFICATION_PENDING', value: 17},
    			{name:'ASSIST_CONVERT', value: 15},
    			{name:'VERIFIED', value: 20}, 
    			{name:'CUSTOMER_CANCEL', value: 210},
    			{name:'ADMIN_CANCEL', value: 200}
    	],

    PAYMENT_FAILED: [
    			{name:'ASSIST_CONVERT', value: 15},
    			{name:'CUSTOMER_CANCEL', value: 210},
    			{name:'ADMIN_CANCEL', value: 200}
    	],

    ASSIST_CONVERT: [
    			{name:'VERIFICATION_PENDING', value: 17},
    			{name:'VERIFIED', value: 20}, 
    			{name:'CUSTOMER_CANCEL', value: 210},
    			{name:'ADMIN_CANCEL', value: 200}
    	],

    VERIFICATION_PENDING: [
    			{name:'VERIFIED', value: 20}, 
    			{name:'CUSTOMER_CANCEL', value: 210},
    			{name:'ADMIN_CANCEL', value: 200}
    	],

    VERIFIED: [
    			{name:'SUPPLIER_ASSIGNED', value: 30},
    			{name:'CUSTOMER_CANCEL', value: 210},
    			{name:'ADMIN_CANCEL', value: 200}
    	],

    SUPPLIER_ASSIGNED: [
    			{name:'PRODUCT_PROCURED', value: 40},
    			{name:'FAILED_SUPPLIER', value: 25},
    			{name:'CUSTOMER_CANCEL', value: 210},
    			{name:'ADMIN_CANCEL', value: 200}
    	],

    FAILED_SUPPLIER: [
    			{name:'SUPPLIER_ASSIGNED', value: 30},
    			{name:'CUSTOMER_CANCEL', value: 210},
    			{name:'ADMIN_CANCEL', value: 200}
    	],

    PRODUCT_PROCURED: [
    			{name:'LSP_ASSIGN', value: 50},
    			{name:'CUSTOMER_CANCEL', value: 210},
    			{name:'ADMIN_CANCEL', value: 200}
    	],

    LSP_ASSIGN: [
    			{name:'MANIFEST_ORDER', value: 60},
    			{name:'CUSTOMER_CANCEL', value: 210},
    			{name:'ADMIN_CANCEL', value: 200}
    	],

    MANIFEST_ORDER: [
    			{name:'SHIPPED', value: 70},
    			{name:'ADMIN_CANCEL', value: 200}
    	],

    SHIPPED: [
    			{name:'DELIVERED', value: 100},
    			{name:'LOST', value: 90},
    			{name:'RETURN_TO_ORIGIN', value: 80}
    	],

    RETURN_TO_ORIGIN: [
    			{name:'DELIVERED', value: 100},
    			{name:'LOST', value: 90},
    			{name:'UNDELIVERED', value: 105}
    	],

    DELIVERED: [
    			{name:'RETURN_INTIATED', value: 110}
    	],

    UNDELIVERED: [],
    	
  }];

  itemAction :  any ;
  itemStatusAction="" ;
showPodupload: boolean = false;
  showRemarks: boolean = false;
  showPaymentTransaction: boolean = false;
  showReason : boolean = false;
  showWareHouseList: boolean = false;
  showSupplierList : boolean = false;
  showLogisticList : boolean = false;
  showPreviousLogistice : boolean = false;
  showShipmentDetail : boolean = false;
  showStatusDate : boolean = false;
  supplierInfoList : any = [];
  selecedFile: File = null;
  selectedNextStatus: any = 0;
  statusFOrm  =  new FormGroup({
  	// supplier assign
    warehouseId :  new FormControl(''),
    supplierId :  new FormControl(''),

	// verified
    transactionId: new FormControl(''),

    // lsp_assign
    shipperId: new FormControl(''),
    shipperAwb : new FormControl(''),

    //Manifest
    length: new FormControl(''),
    width: new FormControl(''),
    height: new FormControl(''),
    weight: new FormControl(''),

    //
    statusDate: new FormControl(''),

    remark: new FormControl('')
  })
  options : any;

  userDetail:any;

  ngOnInit( ): void {
    this.authService.actionItem.subscribe((actionItem)=>{
      this.itemAction = actionItem;
      this.itemStatusAction= this.itemAction?.itemStatus;
      this.options = this.newStateTransitionMap[0][this.itemStatusAction]
    })

    if(this.itemStatusAction == 'VERIFIED' || this.itemStatusAction=='FAILED_SUPPLIER' ){
    	let req = {"itemId" : this.itemAction.itemId
      			};
    	this.authService.getSupplierListbyItem(req).subscribe((response)=>{
	        if(response.code == 200 && response.success){
	        	this.supplierInfoList = response?.data;
	        }else{
	          alert(response.message);
	          this.dialogRef.close();
	        }
	    }, (err)=>{
	        console.log(err.message);
	    });
    }


	

  }
  onFileSelected(event){
    this.selecedFile = <File>event.target.files[0];
  }

  deleteFile(){
     // this.userFile.nativeElement.value = null;
      this.selecedFile = null;
  }

  upDateStatus(){
	let formDat: FormData = new FormData();


	if(this.selecedFile != null){
        formDat.append('image', this.selecedFile, this.selecedFile?.name);
     }
	 //else{
    //     formDat.append('image', '');
    // }
	
      	var supplier_id : any;
        var transferPrice : any;
        var taxPercent : any;
        var warehouse_id : any;

	    if(this.showWareHouseList == true ){
	      	if(this.statusFOrm.get('warehouseId').value == ''){
		        alert("Please select warehouse");
		        return 
	        }
	        else if(this.statusFOrm.get('supplierId').value == ''){
		        alert("Please select supplierId");
		        return 
	        }
	        supplier_id = this.statusFOrm.get('supplierId').value;
	        warehouse_id = this.statusFOrm.get('warehouseId').value;

	        for(var i=0;i<this.supplierInfoList.length;i++){
	        	if(this.supplierInfoList[i].supplierId == supplier_id){
	        	console.log(this.supplierInfoList);
	        		transferPrice = this.supplierInfoList[i].transferPrice;
	        		taxPercent = this.supplierInfoList[i].taxPercent;
	        	}
	        }
	    }

	    if(this.showLogisticList == true){
	      	if(this.statusFOrm.get('shipperId').value == ''){
	      		alert("Please select a Logistic partner");
	        	return 
	      	}
	      	else if(this.statusFOrm.get('shipperAwb').value == ''){
	      		alert("Please enter valid awb no");
	        	return 
	      	}
	        
	    }

	      if(this.showShipmentDetail == true){
	      	if(this.statusFOrm.get('length').value == '' || this.statusFOrm.get('width').value == ''|| this.statusFOrm.get('height').value == '' || this.statusFOrm.get('weight').value == '' ){
	      		alert("Please enter packet dimension");
	        	return 
	      	}
	      }

	      this.userDetail = JSON.parse(window.localStorage.getItem("auth-user") );

	      let req = {"itemId" : this.itemAction.itemId,  
	      			"updateStatus" : this.selectedNextStatus.value ,
	      			"userEmail": this.userDetail.email,
	      			"userId": this.userDetail.id 
	      			};
	      
	      Object.keys( this.statusFOrm.value).forEach(element => {
	        if(this.statusFOrm.value?.[element] != ''){
	          if(element == 'statusDate'){
	            req['deliveryDate'] =  this.statusFOrm.value?.[element];
	          }

	          if(this.showSupplierList){
	          	//req['supplierId'] =  supplierId;
	          	//req['warehouseId'] =  warehouseId;
	          	req['taxPercent'] =  taxPercent;
	          	req['transferPrice'] =  transferPrice;
	          }


	          req[element] = this.statusFOrm.value?.[element];
	        }
	      });

	      console.log(req);
          formDat.append('orderStatus', JSON.stringify(req));
    this._commonService.showLoader();
	     this.authService.update_Customer_Order(formDat).subscribe((response)=>{
	        if(response.code == 200 && response.success){
	          alert("Item Status Successfully update");
	          this.dialogRef.close();
	        }else{
	          alert(response.message);
	          this.dialogRef.close();
	        }
					this._commonService.hideLoader();
	     }, (err)=>{
	        alert(err.error.error);
					this._commonService.hideLoader();
	     });


  }

  enableOrNot(){

    this.showRemarks = false;
    this.showPaymentTransaction = false;
    this.showReason  = false;
    this.showWareHouseList= false;
    this.showSupplierList  = false;
    this.showLogisticList = false;
    this.showPreviousLogistice = false;
    this.showShipmentDetail  = false;
    this.showStatusDate  = false;

    if(this.selectedNextStatus.name == 'VERIFIED'){

      this.showPaymentTransaction = true

    }else if(this.selectedNextStatus.name =='SUPPLIER_ASSIGNED'){
    	console.log("testing Supplier Assign");
    	this.userDetail = JSON.parse(window.localStorage.getItem("auth-user") );
    	console.log(this.userDetail);
    	console.log(this.userDetail.email);
      this.showWareHouseList = true; this.showSupplierList = true
     
    }else if(this.selectedNextStatus.name == 'LSP_ASSIGN'){
      
      this.showLogisticList = true;this.showPreviousLogistice =true
     
    }else if(this.selectedNextStatus.name =='MANIFEST_ORDER'){
    	console.log("testing manifest");
      this.showShipmentDetail = true
        
    }else if(this.selectedNextStatus.name == 'DELIVERED'){

      this.showStatusDate = true
	  this.showPodupload =true

    } 

    if(this.selectedNextStatus.name == 'FAILED_SUPPLIER' || this.selectedNextStatus.name == 'LSP_ASSIGN'||this.selectedNextStatus.name == 'CUSTOMER_CANCEL'||this.selectedNextStatus.name == 'ADMIN_CANCEL' || this.selectedNextStatus.name == 'RETURN_INTIATED'){
    	this.showReason=true
    }
    this.showRemarks = true
  }


}
