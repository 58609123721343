<div class="mogb_paginationrow clearfix" *ngIf="count > pageSize">
    <mat-form-field appearance="fill" style="position: relative; left: -15px; top: -5px; width: 140px;">
        <mat-label>Select Page Size</mat-label>
        <mat-select  [(value)]="selectedOption" (selectionChange)="updatePageSize()">
          <mat-option *ngFor="let item of pageSizeOptions" [value]='item'>{{item}}</mat-option>
        </mat-select>
    </mat-form-field>
    <span  style="position: relative; top: 6px;">Showing {{getPageStart()}} - {{this.pageSize}} of {{count}} </span>
    <ul class="mogb_pagination">
        <li [class]="pageNumber>1?'active':'non-active'" (click)="setPageByControl('FIRST')"
            *ngIf="displayPageControls() && pageNumber !== 1">
            <span>First</span>
        </li>
        <!-- <li [class]="pageNumber>1?'active':'non-active'" (click)="setPageByControl('PREV')"
            *ngIf="displayPageControls()">
            <span>Prev</span>
        </li> -->
        <li *ngFor="let page of pages;index as i;" [class]="pageNumber == (i+1)?'active':'non-active'"
            (click)="setPageByValue(i+1)"
            [style.display]="(i+1)>=pageDisplay &&  maxPagesToDisplay>=(i+1)?'inline-block':'none'">
            <span>{{page}}</span>
        </li>
        <!-- <li [class]="totalPages>pageNumber?'active':'non-active'" (click)="setPageByControl('NEXT')"
            *ngIf="displayPageControls()">
            <span>Next</span>
        </li> -->
        <li [class]="totalPages>pageNumber?'active':'non-active'" (click)="setPageByControl('LAST')"
            *ngIf="displayPageControls() && pageNumber !== pages[pages.length-1]">
            <span>Last</span>
        </li>
    </ul>
</div>