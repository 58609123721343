<mat-dialog-content class="mat-typography ">

  <div class="flex-center justify-between">
    <h2 class="modal-heading m-0">Customer ID <span class="ml-5">922337</span> </h2>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close class="close-btn"><span class="material-icons">
          close
        </span></button>
    </mat-dialog-actions>
  </div>
  <h3 class="modal-sub-heading"> Item ID <span class="ml-5"> 922337</span> </h3>
  <mat-tab-group class="btn-tabs " [@.disabled]="true">
    <mat-tab label="Customer info">
      <div class="modal-table">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table mt-20">

          <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

          <!-- Order Id  -->
          <ng-container matColumnDef="orderId">
            <th mat-header-cell *matHeaderCellDef>Order Id </th>
            <td mat-cell *matCellDef="let element"> {{element.orderId}} </td>
          </ng-container>

          <!-- Item Id Column -->
          <ng-container matColumnDef="itemId">
            <th mat-header-cell *matHeaderCellDef> Item Id </th>
            <td mat-cell *matCellDef="let element"> {{element.itemId}} </td>
          </ng-container>

          <!-- Product Ref Column -->
          <ng-container matColumnDef="productRef">
            <th mat-header-cell *matHeaderCellDef> Product Ref </th>
            <td mat-cell *matCellDef="let element"> {{element.productRef}} </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
          </ng-container>

          <!-- Created Column -->
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef> Created At </th>
            <td mat-cell *matCellDef="let element"> {{element.createdAt}} </td>
          </ng-container>
          <!-- Shipped Column -->
          <ng-container matColumnDef="shippedAt">
            <th mat-header-cell *matHeaderCellDef> Shipped At </th>
            <td mat-cell *matCellDef="let element"> {{element.shippedAt}} </td>
          </ng-container>
          <!-- Remark Column -->
          <ng-container matColumnDef="delivered">
            <th mat-header-cell *matHeaderCellDef> Delivered At </th>
            <td mat-cell *matCellDef="let element"> {{element.delivered}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="customerInfoDisplayedCloumn"></tr>
          <tr mat-row *matRowDef="let row; columns: customerInfoDisplayedCloumn;"></tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Address">
      <div class="d-flex justify-right">
        <button mat-raised-button color="warn">Update Address</button>
      </div>

      <div class="d-flex justify-between">
        <div class="address-info">
          <fieldset form="form1" class="outline-form">
            <legend>
              <h4>Billing</h4>
            </legend>
            <form class="example-form">
              <div class="d-flex justify-between mb-20">
                <fieldset class="theme-input-box">
                  <legend class="required">First name</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter first name" value="Prakash">
                  </mat-form-field>

                </fieldset>
                <fieldset class="theme-input-box">
                  <legend class="required">Address line 1</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter address line 1" value="Tagore Garden">
                  </mat-form-field>
                </fieldset>
              </div>
              <div class="d-flex justify-between mb-20">
                <fieldset class="theme-input-box">
                  <legend class="required">Address line 2</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter address line 2" value="Delhi">
                  </mat-form-field>
                </fieldset>
                <fieldset class="theme-input-box">
                  <legend class="required">City</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter city" value="South West Delhi">
                  </mat-form-field>
                </fieldset>
              </div>
              <div class="d-flex justify-between mb-20">
                <fieldset class="theme-input-box">
                  <legend class="required">State</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-select [(value)]="selected">
                      <mat-option value="Delhi">Delhi</mat-option>
                      <mat-option value="option">Option</mat-option>
                      <mat-option value="option">Option</mat-option>
                    </mat-select>
                  </mat-form-field>
                </fieldset>
                <fieldset class="theme-input-box">
                  <legend class="required">Pincode</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter pincode" value="110006">
                  </mat-form-field>
                </fieldset>
              </div>
              <div class="d-flex justify-between mb-20">
                <fieldset class="theme-input-box">
                  <legend class="required">Country</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-select [(value)]="country">
                      <mat-option value="India">India</mat-option>
                      <mat-option value="option">Option</mat-option>
                      <mat-option value="option">Option</mat-option>
                    </mat-select>
                  </mat-form-field>
                </fieldset>
                <fieldset class="theme-input-box">
                  <legend class="required">Phone no.</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter phone no." value="9876543210">
                  </mat-form-field>
                </fieldset>
              </div>
              <div class="d-flex justify-between mb-20">
                <fieldset class="theme-input-box">
                  <legend class="required">Alt Phone no.</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter alt phone no." value="9876443211">
                  </mat-form-field>

                </fieldset>
                <fieldset class="theme-input-box">
                  <legend class="required">GSTIN</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter GSTIN" value="36Aajcm7312H1Zf">
                  </mat-form-field>
                </fieldset>


              </div>
            </form>
          </fieldset>
          <div class="flex-center checkbox-1 my-15 ml-20">
            <section class="example-section checkbox-1 flex-center">
              <mat-checkbox [checked]="checked" class="example-margin">Set As Default</mat-checkbox>
            </section>
            <section class="example-section ml-20 flex-center checkbox-1">
              <mat-checkbox class="example-margin">Use For pickup address</mat-checkbox>
            </section>
          </div>

        </div>
        <div class="address-info">

          <fieldset form="form1" class="outline-form">
            <legend>
              <h4>Pickup</h4>
            </legend>
            <form class="example-form">
              <div class="d-flex justify-between mb-20">
                <fieldset class="theme-input-box">
                  <legend class="required">First name</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter first name" value="Prakash">
                  </mat-form-field>

                </fieldset>
                <fieldset class="theme-input-box">
                  <legend class="required">Address line 1</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter address line 1" value="Tagore Garden">
                  </mat-form-field>
                </fieldset>
              </div>
              <div class="d-flex justify-between mb-20">
                <fieldset class="theme-input-box">
                  <legend class="required">Address line 2</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter address line 2" value="Delhi">
                  </mat-form-field>
                </fieldset>
                <fieldset class="theme-input-box">
                  <legend class="required">City</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter city" value="South West Delhi">
                  </mat-form-field>
                </fieldset>
              </div>
              <div class="d-flex justify-between mb-20">
                <fieldset class="theme-input-box">
                  <legend class="required">State</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-select [(value)]="selected">
                      <mat-option value="Delhi">Delhi</mat-option>
                      <mat-option value="option">Option</mat-option>
                      <mat-option value="option">Option</mat-option>
                    </mat-select>
                  </mat-form-field>
                </fieldset>
                <fieldset class="theme-input-box">
                  <legend class="required">Pincode</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter pincode" value="110006">
                  </mat-form-field>
                </fieldset>
              </div>
              <div class="d-flex justify-between mb-20">
                <fieldset class="theme-input-box">
                  <legend class="required">Country</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-select [(value)]="country">
                      <mat-option value="India">India</mat-option>
                      <mat-option value="option">Option</mat-option>
                      <mat-option value="option">Option</mat-option>
                    </mat-select>
                  </mat-form-field>
                </fieldset>
                <fieldset class="theme-input-box">
                  <legend class="required">Phone no.</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter phone no." value="9876543210">
                  </mat-form-field>
                </fieldset>
              </div>
              <div class="d-flex justify-between mb-20">
                <fieldset class="theme-input-box">
                  <legend class="required">Alt Phone no.</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter alt phone no." value="9876443211">
                  </mat-form-field>

                </fieldset>
                <fieldset class="theme-input-box">
                  <legend class="required">GSTIN</legend>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input matInput placeholder="Enter GSTIN" value="36Aajcm7312H1Zf">
                  </mat-form-field>
                </fieldset>


              </div>
            </form>
          </fieldset>
          <div class="flex-center checkbox-1 my-15 ml-20">
            <section class="example-section checkbox-1 flex-center">
              <mat-checkbox [checked]="checked" class="example-margin">Set As Default</mat-checkbox>
            </section>

          </div>

        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</mat-dialog-content>