import { Component, OnInit } from '@angular/core';

import { TokenStorageService } from '../_services/token-storage.service';
import { AuthService } from '../_services/auth.service';

import { Router } from '@angular/router';

@Component({
	selector: 'app-editaccess',
	templateUrl: './editaccess.component.html',
	styleUrls: ['./editaccess.component.scss']
})
export class EditaccessComponent implements OnInit {

	errorMessage = '';
	displayedColumns = ['Id', 'UserName', 'Email', 'Team', 'Role', 'Status', 'Action'];
	dataSource: any;
	isMenuListResponse = false;
	isMenuListError = false;

	constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router) { }

	addUserinfoSession(obj) {
		localStorage.setItem("edituserinfo", JSON.stringify(obj));
		this.router.navigateByUrl('/updateaccess/' + obj.id)
	}

	ngOnInit(): void {
		console.log(this.tokenStorage.getUser().team);
		console.log(this.tokenStorage.getUser().role);
		this.authService.myUserList(this.tokenStorage.getUser().team, this.tokenStorage.getUser().role).subscribe(
			data => {
				console.log(data);
				if (data.success) {
					this.isMenuListResponse = true;
					this.dataSource = data.data;
					console.log(data);
				}

			},
			err => {
				this.errorMessage = err.error.message;
				this.isMenuListError = true;
			}
		);
	}

}
