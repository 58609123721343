import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';

import { ProfileComponent } from './profile/profile.component';

import { AddmenuComponent } from './addmenu/addmenu.component';
import { EditaccessComponent } from './editaccess/editaccess.component';
import { UpdateaccessComponent } from './updateaccess/updateaccess.component';
import { CustomerOrderComponent } from './customer-order/customer-order.component';
import { IsNotAuthenticatedGuard } from './_helpers/guards/is-not-authenticated.guard';
import { BulkorderUploadComponent } from './bulkorder-upload/bulkorder-upload.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent , canActivate: [IsNotAuthenticatedGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [IsNotAuthenticatedGuard] },
  { path: 'feature/add', component: AddmenuComponent, canActivate: [IsNotAuthenticatedGuard] },
  { path: 'feature/editaccess', component: EditaccessComponent, canActivate: [IsNotAuthenticatedGuard] },
  { path: 'updateaccess/:id', component: UpdateaccessComponent, canActivate: [IsNotAuthenticatedGuard] },
  { path: 'customer-order', component: CustomerOrderComponent, canActivate: [IsNotAuthenticatedGuard] },
  { path: 'customer-order/bulkorder-upload',component:BulkorderUploadComponent, canActivate: [IsNotAuthenticatedGuard]},
  { path: 'purchase-order', loadChildren: () => import('./purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule), canActivate: [IsNotAuthenticatedGuard] },
  { path: 'payout', loadChildren: () => import('./payout/payout.module').then(m => m.PayoutModule), canActivate: [IsNotAuthenticatedGuard]},
  { path: 'finance', loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule), canActivate: [IsNotAuthenticatedGuard]},
  { path: 'monthly-reporting',loadChildren: () => import('./monthly-reporting/monthly-reporting.module').then(m => m.MonthlyReportingModule), canActivate: [IsNotAuthenticatedGuard]},


  { path: 'damage-business',loadChildren: () => import('./damage-payout/damage-payout.module').then(m => m.DamagePayoutModule), canActivate: [IsNotAuthenticatedGuard]},
  { path: 'damage-finance',loadChildren: () => import('./damage-finance/damage-finance.module').then(m => m.DamageFinanceModule), canActivate: [IsNotAuthenticatedGuard]},
  { path: 'damage-logistic',loadChildren: () => import('./damage-logistic/damage-logistic.module').then(m => m.DamageLogisticModule), canActivate: [IsNotAuthenticatedGuard]},
  // { path: 'logistic',loadChildren: () => import('./logistic/logistic.module').then(m => m.LogisticModule), canActivate: [IsNotAuthenticatedGuard]},
  {path: 'customer-order/bulkorder-upload',component:BulkorderUploadComponent, canActivate: [IsNotAuthenticatedGuard]},
  { path: '', redirectTo: 'home', pathMatch: 'full'},


];


export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
