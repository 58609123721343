<!--  <div class="col-md-12">
  <div class="card card-container">
    <img
      id="profile-img"
      src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" 
      class="profile-img-card"
    />
    <form
      *ngIf="!isLoggedIn"
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      novalidate
    >
      <div class="form-group">
        <label for="email">User Email</label>
        <input
          type="text"
          class="form-control"
          name="email"
          [(ngModel)]="form.email"
          required
          #email="ngModel"
        />
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="email.errors && f.submitted"
        >
          UserEmail is required!
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          class="form-control"
          name="password"
          [(ngModel)]="form.password"
          required
          minlength="6"
          #password="ngModel"
        />
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="password.errors && f.submitted"
        >
          <div *ngIf="password.errors.required">Password is required</div>
          <div *ngIf="password.errors.minlength">
            Password must be at least 6 characters
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary btn-block">
          Login
        </button>
      </div>
      <div class="form-group">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="f.submitted && isLoginFailed"
        >
          Login failed: {{ errorMessage }}
        </div>
      </div>
    </form>

    <div class="alert alert-success" *ngIf="isLoggedIn">
      Logged in as {{ role }}.
    </div>
  </div>
</div> -->
<!-- <div class="card card-container">
<mat-card>
  <mat-card-title>Login</mat-card-title>
<mat-card-content>
<form [formGroup]="form" (ngSubmit)="submit()">
<p>
  <mat-form-field>
    <input type="text" matInput placeholder="Username" formControlName="username">
  </mat-form-field>
</p>

<p>
  <mat-form-field>
    <input type="password" matInput placeholder="Password" formControlName="password">
  </mat-form-field>
</p>

<p *ngIf="error" class="error">
  {{ error }}
</p>

<div class="button">
  <button type="submit" mat-button>Login</button>
</div>

</form>
</mat-card-content>
</mat-card>
</div> -->
<!-- <mat-toolbar color="primary">
    <mat-toolbar-row>
      <div>
        <img class="menu-icon" src="../assets/images/Component 4 – 1.svg" alt="">
      </div>
      <span class="example-fill-remaining-space"></span>
      <span class="align-center"></span>
      <span class="example-spacer"></span>
      <button mat-button class="span_button">About</button>
      <button mat-button class="span_button" (click)="navigateTo()">Signup</button>
    </mat-toolbar-row>
  </mat-toolbar> -->
<!-- <mat-card>
  <mat-card-header>
    <mat-card-title>Login in to OMS</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="col-md-12">
      <div class="card card-container">
        <img id="profile-img" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" class="profile-img-card img" />
        <div>
          <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
            <div class="form-group-main">
              <div class="form-group">
                <label for="email">User Email<em>*</em></label>
                <input type="text" class="form-control" name="email" [(ngModel)]="form.email" required #email="ngModel"
                  (change)="setValidity()" />
                <div class="alert alert-danger" role="alert" *ngIf="email.errors && f.submitted">
                  UserEmail is required!
                </div>
              </div>
              <div class="form-group">
                <label for="password">Password<em>*</em></label>
                <input type="password" class="form-control" name="password" [(ngModel)]="form.password" required
                  minlength="6" #password="ngModel" (change)="setValidity()" />
                <div class="alert alert-danger" role="alert" *ngIf="password.errors && f.submitted">
                  <div *ngIf="password.errors.required">Password is required</div>
                  <div *ngIf="password.errors.minlength">
                    Password must be at least 6 characters
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button class="btn btn-primary btn-block">
                  Login
                </button>
              </div>
            </div>

            <div class="form-group">
              <div class="alert alert-danger" role="alert" *ngIf="f.submitted && isLoginFailed">
                Login failed: {{ errorMessage }}
              </div>
              <button class="btn btn-primary btn-block" (click)="microsoftLogin()">
                Login microsoft
              </button>
            </div>
          </form>
        </div>

        <div class="alert alert-success" *ngIf="isLoggedIn">
          Logged in as {{ role }}.
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>  -->


<section *ngIf="showLoginCard" class="omsLoginElementWrap">
  <div class="innerContent">
    <span class="logoBlk">
      <img src="../assets/images/Component 4 – 1.svg" alt="">
    </span>
    <strong class="projectText">
      Welcome to OMS 2.0
    </strong>

    <button class="micorsoftLogin" (click)="microsoftLogin()">
      <img src="../assets/images/microsoft.svg" alt="logo">
      Signin with Moglix Account
    </button>
  </div>
</section>