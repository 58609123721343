import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  loaderIsVisible: boolean = false;

  showLoader() {
    this.loaderIsVisible = true;
  }
  
  hideLoader() {
    this.loaderIsVisible = false;
  }
}