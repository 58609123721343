import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../_services/token-storage.service';
import { AuthService } from '../_services/auth.service';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import {Router} from '@angular/router';
import * as _ from 'lodash';
@Component({
  selector: 'app-updateaccess',
  templateUrl: './updateaccess.component.html',
  styleUrls: ['./updateaccess.component.scss']
})
export class UpdateaccessComponent implements OnInit {
//=====intiating variables
  roleList: any = [];
  userData : any;
  dataSource : any;
  isFeatureListResponse = false;
  errorMessage = '';
  isSuccessful:false;
  selectedFeaturelist:any;
  checkedIDs : any;
  login_User : any;

  form: any = {
    userid:null,
    role: null,
    status: null,
    featurelist : null
  };
  child : any;
  parent: any;

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router) {}

  
  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("edituserinfo"));
    console.log(this.userData);
    this.login_User = this.tokenStorage.getUser();
    if(this.login_User.team == "Tech"){
      this.roleList = ['Admin','Manager', 'User', 'Viewonly'];
    }else{
      this.roleList = ['Manager', 'User', 'Viewonly'];
    }
    this.form.status = this.userData.status;
    this.form.role = this.userData.role;
    this.getFeaturesListByuserId();
   // this.getActive_List();
  }

  getFeaturesListByuserId() {
    //this.authService.listFeature(this.userData.id, this.tokenStorage.getUser().team, this.tokenStorage.getUser().role).
    this.authService.listFeature(this.login_User.id, this.login_User.team, this.login_User.role).subscribe(
      data => {
        console.log(data);
        if (data.success) {
          this.isFeatureListResponse = true;
          this.dataSource = data.data;
         this.parent = this.dataSource.filter((element)=>{
            if(element.featureParent == null){
              return element;
            }
          });
          this.child = this.dataSource.filter((element)=>{
            if(element.featureParent != null){
              return element;
            }
          });
        }
        this.getActive_List();
      },
      err => {
        this.errorMessage = err.error.message;
        this.isFeatureListResponse = true;
      });
  }

  getActive_List(){
    this.authService.getActive_list(this.userData.id).subscribe((data)=>{
      if (data.success) {
        let dataSource = data.data;
        dataSource.forEach(element => {
          if (element.status == 1) {
            element['isChecked'] = true;
            element['childList'] = []
          } else {
            element['isChecked'] = false;
            element['childList'] = []
          }
        });

        let parentData = dataSource.filter((element, index)=>{
          if(element.featureParent == null){
            this.parent.unshift(element);
            return element;
          }
        });

        for (let i = 0; i < parentData.length; i++) {
          for (let j = 0; j < this.parent.length; j++) {
            if(this.parent[j].id == parentData[i].id && (this.parent[j]['isChecked'] == false || this.parent[j]['isChecked'] == undefined )){
              this.parent.splice(j, 1);
            }
          }
        }

        let childdata = dataSource.filter((element)=>{
          if(element.featureParent != null){
            this.child.unshift(element)
            return element;
          }
        });

        for (let i = 0; i < childdata.length; i++) {
          for (let j = 0; j < this.child.length; j++) {
            if(this.child[j].id == childdata[i].id && (this.child[j]['isChecked'] == false || this.child[j]['isChecked'] == undefined )){
              this.child.splice(j, 1);
            }
          }
        }
      }
    })
  }

  setChecks(selectedMenu){
    // if uncheck parent feature menu then uncheck all child menus
    console.log("====selectedMenu", selectedMenu);
    this.child.forEach(menuItem => {
      if(selectedMenu['isChecked'] == false && menuItem.featureParent == selectedMenu.featureName){
        menuItem['isChecked'] = false;
      } 
      //if(selectedMenu.featureName =='')
    });
//     featureMaskingName: "menulist"
// featureName: "Menu List"
// featureParent: "Add Menu"
// id: 1
// status: 1
// url: "/feature/list"
    

    // if uncheck child 

    // provide default hecks 
  }

  onSubmit(): void {
    this.checkedIDs = [];
    this.parent.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDs.push(value.id);
      }
    });
    this.child.forEach(element => {
      if (element.isChecked) {
        this.checkedIDs.push(element.id);
      }
    });
    console.log(this.checkedIDs);
    const { role, status } = this.form;
    console.log(this.userData.id);
    console.log(role);
    console.log(status);
    this.authService.updateAccess(this.userData.id, role, status, this.checkedIDs).subscribe(
      data => {
        console.log(data);
        if (data.success) {
          this.isFeatureListResponse = true;
          this.dataSource = data.data;
          console.log(data);
        }
        this.router.navigateByUrl('/feature/editaccess');
      //  this.getFeaturesListByuserId();
      },
      err => {
        this.errorMessage = err.error.message;
        this.isFeatureListResponse = true;
      }
    );
  }
}
