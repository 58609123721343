<mat-dialog-content>
	<p>
		{{message}}
	</p>
	<div class="form-group">
		<label for="country">Country</label>
		<select class="form-control" id="country" name="country" [(ngModel)]="country"
			(change)="setCountry($event.target.value)" required>
			<option value="in">India</option>
			<option value="me">Middle East</option>
		</select>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button mat-raised-button color="primary" [disabled]="isDisabled" (click)="onConfirmClick()"
		tabindex="1">{{confirmButtonText}}</button>
</mat-dialog-actions>