import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import {Router} from '@angular/router';
import { Md5 } from "ts-md5/dist/md5";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  teamList = ['Tech','Procurement', 'Operation', 'CustomerSupport','Finance'];

  public routerLinkVariable = "/login"; 
  form: any = {
    
    name: null,
    email: null,
    team: null,
    password: null
    
  };
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    const {email, team, password } = this.form;
    if(email?.split("@")[1] != 'moglix.com'){
      alert("Pleas provide moglix emails only");
      this.form.email = null;
      return;
    }
    if(team == 'Select Team'){
      alert("Pleas select a valid team");
      this.form.team = null;
      return;
    }
    let name  = this.form?.email?.split("@")[0];
    this.authService.signup(name, email, team, Md5.hashStr(password)).subscribe(
      data => {
        console.log(data);
        if(data.success){
          this.isSuccessful = true;
          console.log(data.message);
          alert("User created successfully");
          // setTimeout(() => {
          //   this.router.navigateByUrl('/login');
          // }, 2000);
        }
        else{
          this.isSignUpFailed = true;
          this.errorMessage = data.message;
          console.log(data.message);
        }
      },
      err => {
        this.errorMessage = err.error.message;
        this.isSignUpFailed = true;
      }
    );
  }
}

