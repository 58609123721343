<div class="oms-wrapper">
  <h1 class="heading-one">Order</h1>
</div>
<div class="oms-wrapper d-flex">
  <div class="input-group input-addon ">
    <input type="text" class="form-control" placeholder="Search">
    <div class="input-group-btn ">
      <mat-form-field appearance="fill">
        <mat-label>Order Id</mat-label>
        <mat-select>
          <mat-option value="one">First option</mat-option>
          <mat-option value="two">Second option</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <mat-form-field class="ml-20 select-1" appearance="fill">
    <mat-label>State</mat-label>
    <mat-select>
      <mat-option>None</mat-option>
      <mat-option>sdfsdf</mat-option>
    </mat-select>
  </mat-form-field>
  <a class="ml-20 flex-center" href="javascript:void(0)"> <img src="../assets/images/download.svg" alt=""> </a>
</div>

<div class="oms-wrapper d-flex my-8 ml-20">
  <section class="example-section checkbox-1 flex-center">
    <mat-checkbox class="example-margin">Basic Info.</mat-checkbox>
  </section>
  <section class="example-section ml-20 flex-center checkbox-1">
    <mat-checkbox class="example-margin">Customer Info.</mat-checkbox>
  </section>
  <section class="example-section ml-20 flex-center checkbox-1">
    <mat-checkbox class="example-margin ">Supplier Details</mat-checkbox>
  </section>
  <section class="example-section ml-20 flex-center checkbox-1">
    <mat-checkbox class="example-margin">Delivered Orders</mat-checkbox>
  </section>
  <section class="example-section ml-20 flex-center checkbox-1">
    <mat-checkbox class="example-margin">Recent Remarks</mat-checkbox>
  </section>
  <button class="ml-5" mat-button [matMenuTriggerFor]="menu">More <span class="material-icons"> arrow_drop_down </span></button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>Item 1</button>
    <button mat-menu-item>Item 2</button>
  </mat-menu>
  <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->
  <!-- <div class="hover-tooltip tooptip-style-1"><span class="material-icons">info</span>
   <div class="tooltip-msg-box">
     <div class="tooltip-msg-left d-flex flex-column w-50 ">
      <h5 class="text-nowrap">Order Value</h5>
      <ul class="text-nowrap">
        <li>Order: +350</li>
        <li>Discount: -0</li>
        <li>Shipping: +50</li>
        <li>Total =400</li>
      </ul>
     </div>
     <div class="tooltip-msg-right d-flex flex-column w-50 ">
      <h5 class="text-nowrap">Item Value</h5>
      <ul class="text-nowrap">
        <li>Price: +350</li>
        <li>Discount: -0</li>
        <li>Fix: +0</li>
        <li>Shipping: +50</li>
      </ul>
     </div>
   </div>
  </div> -->
  <!-- <button class="ml-5" mat-button [matMenuTriggerFor]="menu4"><span class="material-icons">
    more_horiz
    </span></button>
  <mat-menu #menu4="matMenu" class="checkbox-dropdown-2 ">
    <div class="checkbox-dropdown-section d-flex flex-column px-10">
      <button mat-menu-item>History</button>
      <button mat-menu-item>Split</button>
      <button mat-menu-item>Add Comment</button>
      <button mat-menu-item>Download Invoice</button>
      <button mat-menu-item>Packaging</button>
      <button mat-menu-item>Assign To</button>
     
    </div>
  </mat-menu> -->
 </div>