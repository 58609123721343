
<mat-dialog-content class="mat-typography modal-raduis">

  <div class="flex-center justify-between">
<h2 class="modal-heading m-0">History  </h2><mat-dialog-actions align="end">
  <button style="pointer-events: auto; cursor: pointer;" mat-button mat-dialog-close class="close-btn"><span class="material-icons">
    close
    </span></button>
</mat-dialog-actions>
</div>
<h3 class="modal-sub-heading" *ngIf="historyData.length"> Item ID <span class="ml-5"> {{orderItemHistoryId}}</span> </h3>
<h3 class="modal-sub-heading" *ngIf="purchasrOrderHistory.length"> Po ID <span class="ml-5"> {{historyItemId}}</span> </h3>

<div class="table-responsive plantlistable" *ngIf="historyData.length">
  <table class="table" *ngIf="historyData.length > 0">
      <thead>
          <tr>
              <th class="customer">Email</th>
              <th class="order">Item status</th>
              <th class="order">Supplier Name</th>
              <th class="order">Remark</th>
              <th class="order">Action</th>
              <th class="order">Timestamp</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let item of historyData">
              <td class="customer">{{item?.userEmail}}</td>
              <td class="order">{{item?.itemStatus}}</td>
              <td>{{item?.supplierName}}</td>
              <td>{{item?.remark}}</td>
              <td>{{item?.action}}</td>
              <td class="date">{{item?.createdAt}} </td>
          </tr>
      </tbody>
  </table>
</div>



<div class="table-responsive plantlistable" *ngIf="purchasrOrderHistory.length">
  <table class="table" *ngIf="purchasrOrderHistory.length > 0">
      <thead>
          <tr>
              <th class="customer">poId</th>
              <th class="order">itemId</th>
              <th >poUniqueId</th>
              <th class="order">userId</th>
              <th class="order">name</th>
              <th class="order">email</th>
              <th class="order">itemStatus</th>
              <th class="date">supplierName</th>
              <th class="qty">timeStamp</th>
              <th class="qty">remark</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let item of purchasrOrderHistory">
              <td class="customer">{{item?.poId}}</td>
              <td class="order">{{item?.itemId}}</td>
              <td>{{item?.poUniqueId}}</td>
              <td>{{item?.userId}}</td>
              <td>{{item?.name}}</td>
              <td>{{item?.email}}</td>
              <td>{{ item?.poStatus}}</td>
              <td class="qty">{{item?.businessName }}</td>
              <td class="date">{{item?.createdAt }} </td>
              <td>{{ item?.remark}}</td>
          </tr>
      </tbody>
  </table>
</div>
  <h4 class="noData" *ngIf="nodata"> !!DATA NOT FOUND</h4>
</mat-dialog-content>




